import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Colors from '../assets/styles/Colors'

import OpenIcon from '../assets/JSX/OpenIcon'
import Responsive from '../assets/config/Responsive'
import BreadcrumbArrow from '../assets/JSX/BreadcrumbArrow'
import { useEffect, useState } from 'react'
import useFetch from '../hooks/useFetch'
import Loader from '../assets/components/Loader'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import Dotdotdot from 'react-dotdotdot'
import { useQuery } from 'react-query'
import { Blurhash } from 'react-blurhash'

const Projects = () => {
  const navigate = useNavigate()
  //
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  //

  const { t } = useTranslation()

  const queryKey = 'projectsQuery'

  const fetchProjects = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/projects?populate=*`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      }
    )
    const data = await response.json()
    return data.data
  }

  const { data, isLoading: loading } = useQuery(queryKey, fetchProjects, {
    refetchInterval: 100,
  })

  const [cachedImages, setCachedImages] = useState<any>([])

  useEffect(() => {
    const cacheImages = () => {
      data?.map((item: any, index: any) => {
        const image = new Image()
        image.src =
          process.env.REACT_APP_UPLOAD_URL +
          item.attributes?.mainImage?.data?.attributes?.url
        // image.onload = () => {
        cachedImages.push(image)
        setCachedImages(cachedImages)
        // }
      })
    }
    cacheImages()
    const arr = cachedImages?.slice(1).reverse()
    setCachedImages(arr)
  }, [data])
  const hashes = [
    'LFJS2@~q9Zbw%h4TxaMxIo9ERP?a',
    // 'L1O|nX0000_39Y0000?H000000Rj',
    'LUFQOQozRiaz.9aeayf6?wofWBkC',
    'LMHDmw~pMxs:4oIqogjY00Iqe.R-',
  ]

  const projectCount = data?.length
  const lastProject = data?.slice(0, 1)
  const newData = data?.slice(1).reverse()

  const currentLanguageCode = cookies.get('i18next') || 'en'

  const { data: projectsPageData, loading: projectsPageLoading } = useFetch(
    `/projects-page-${currentLanguageCode}`
  )

  const mainLoading = loading || projectsPageLoading

  return mainLoading ? (
    <Loader />
  ) : (
    <>
      <Breadcrumbs>
        <BreadcrumbInactive onClick={() => navigate('/')}>
          {t('main')}
        </BreadcrumbInactive>
        <BreadcrumbArrowStyle />
        <BreadcrumbActive> {t('projects')}</BreadcrumbActive>
      </Breadcrumbs>

      <Wrapper>
        <TitleSection>
          <PageTiTle>{projectsPageData?.attributes.pageHeading}</PageTiTle>
        </TitleSection>
        <ProjectsSection>
          {projectCount > 1 &&
            newData?.map((item: any, index: any) => (
              <Card key={index} onClick={() => navigate(`/project/${item.id}`)}>
                {/* {cachedImages[index]?.complete ? (
                  <Img src={cachedImages[index]?.src} alt="" loading="lazy" />
                ) : (
                  <BlurhashImg
                    hash={hashes[index % 3]}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                    className="pulse-load"
                  />
                )} */}
                {item.attributes?.youtubeLink ? (
                  <Iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${item.attributes?.youtubeLink?.slice(
                      -11
                    )}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></Iframe>
                ) : (
                  <Img
                    src={
                      process.env.REACT_APP_UPLOAD_URL +
                      item.attributes?.mainImage?.data?.attributes?.url
                    }
                    alt=""
                  />
                )}
                {currentLanguageCode === 'ge' ? (
                  <Description>
                    <Top>
                      <Location>{item.attributes.locationGe}</Location>
                      <OpenIconStyle />
                    </Top>
                    <Dotdotdot clamp={4}>
                      <Name>{item.attributes.titleGe}</Name>
                    </Dotdotdot>
                    {item?.attributes.descriptions?.map(
                      (item: any, index: any) => (
                        <Dotdotdot clamp={4}>
                          <Text key={index}>{item?.descriptionGe}</Text>
                        </Dotdotdot>
                      )
                    )}
                    <Type>{item.attributes.typeGe}</Type>
                  </Description>
                ) : (
                  <Description>
                    <Top>
                      <Location>{item.attributes.locationEn}</Location>
                      <OpenIconStyle />
                    </Top>
                    <Dotdotdot clamp={4}>
                      <Name>{item.attributes.titleEn}</Name>
                    </Dotdotdot>
                    {item?.attributes.descriptions?.map(
                      (item: any, index: any) => (
                        <Dotdotdot clamp={4}>
                          <Text key={index}>{item?.descriptionEn}</Text>
                        </Dotdotdot>
                      )
                    )}
                    <Type>{item.attributes.typeEn}</Type>
                  </Description>
                )}
              </Card>
            ))}
          {projectCount % 3 !== 1 &&
            lastProject?.map((item: any, index: any) => (
              <Card key={index} onClick={() => navigate(`/project/${item.id}`)}>
                {item.attributes?.youtubeLink ? (
                  <Iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${item.attributes?.youtubeLink?.slice(
                      -11
                    )}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></Iframe>
                ) : (
                  <Img
                    src={
                      process.env.REACT_APP_UPLOAD_URL +
                      item.attributes?.mainImage?.data?.attributes?.url
                    }
                    alt=""
                  />
                )}
                {currentLanguageCode === 'ge' ? (
                  <Description>
                    <Top>
                      <Location>{item.attributes.locationGe}</Location>
                      <OpenIconStyle />
                    </Top>
                    <Dotdotdot clamp={4}>
                      <Name>{item.attributes.titleGe}</Name>
                    </Dotdotdot>
                    {item?.attributes.descriptions?.map(
                      (item: any, index: any) => (
                        <Dotdotdot clamp={4}>
                          <Text key={index}>{item?.descriptionGe}</Text>
                        </Dotdotdot>
                      )
                    )}
                    <Type>{item.attributes.typeGe}</Type>
                  </Description>
                ) : (
                  <Description>
                    <Top>
                      <Location>{item.attributes.locationEn}</Location>
                      <OpenIconStyle />
                    </Top>
                    <Dotdotdot clamp={4}>
                      <Name>{item.attributes.titleEn}</Name>
                    </Dotdotdot>
                    {item?.attributes.descriptions?.map(
                      (item: any, index: any) => (
                        <Dotdotdot clamp={4}>
                          <Text key={index}>{item?.descriptionEn}</Text>
                        </Dotdotdot>
                      )
                    )}
                    <Type>{item.attributes.typeEn}</Type>
                  </Description>
                )}
              </Card>
            ))}
        </ProjectsSection>
        {projectCount % 3 === 1 &&
          lastProject?.map((item: any, index: any) => (
            <HorizontalCard
              onClick={() => navigate(`/project/${item.id}`)}
              key={index}
            >
              {item.attributes?.youtubeLink ? (
                <Iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${item.attributes?.youtubeLink?.slice(
                    -11
                  )}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></Iframe>
              ) : (
                <Img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    item.attributes?.mainImage?.data?.attributes?.url
                  }
                  alt=""
                />
              )}
              {currentLanguageCode === 'ge' ? (
                <Description>
                  <Top>
                    <Location>{item.attributes.locationGe}</Location>
                    <OpenIconStyle />
                  </Top>
                  <Dotdotdot clamp={4}>
                    <Name>{item.attributes.titleGe}</Name>
                  </Dotdotdot>
                  {item?.attributes.descriptions?.map(
                    (item: any, index: any) => (
                      <Dotdotdot clamp={4}>
                        <Text key={index}>{item?.descriptionGe}</Text>
                      </Dotdotdot>
                    )
                  )}
                  <Type>{item.attributes.typeGe}</Type>
                </Description>
              ) : (
                <Description>
                  <Top>
                    <Location>{item.attributes.locationEn}</Location>
                    <OpenIconStyle />
                  </Top>
                  <Dotdotdot clamp={4}>
                    <Name>{item.attributes.titleEn}</Name>
                  </Dotdotdot>
                  {item?.attributes.descriptions?.map(
                    (item: any, index: any) => (
                      <Dotdotdot clamp={4}>
                        <Text key={index}>{item?.descriptionEn}</Text>
                      </Dotdotdot>
                    )
                  )}
                  <Type>{item.attributes.typeEn}</Type>
                </Description>
              )}
            </HorizontalCard>
          ))}
      </Wrapper>
      <BottomBorder />
    </>
  )
}
const Wrapper = styled.div`
  ${Responsive.mobile} {
    margin-left: 4vw;
    margin-right: 4vw;
    border-left: 1px solid ${Colors.borderGrey};
    border-right: 1px solid ${Colors.borderGrey};
  }
`
const TitleSection = styled.section`
  width: 33.33333vw;
  text-align: center;
  height: 180px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid ${Colors.borderGrey};
  border-right: 1px solid ${Colors.borderGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(33.33333vw);
  margin-top: 80px;
  ${Responsive.mobile} {
    width: unset;
    border-left: none;
    border-right: none;
    margin-left: unset;
    height: 150px;
  }
`
const PageTiTle = styled.h1`
  width: 387px;
  height: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  font-feature-settings: 'case' on;
  color: #313131;
  ${Responsive.mobile} {
    font-size: 30px;
    line-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const ProjectsSection = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid ${Colors.borderGrey};
  ${Responsive.mobile} {
    display: block;
    border-bottom: none;
  }
  ${Responsive.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`
const Card = styled.div`
  border-right: 1px solid ${Colors.borderGrey};
  width: calc(33.33333vw);
  cursor: pointer;
  :nth-of-type(3n + 0) {
    border-right: none;
  }
  ${Responsive.mobile} {
    width: 100%;
    border-right: none;
  }
  ${Responsive.tablet} {
    width: 49vw;
  }
`
const Img = styled.img`
  margin-left: 2px;
  margin-right: 3px;
  height: 319px;
  width: calc(33.33333vw - 5px);
  /* object-fit: cover; */
  background-repeat: no-repeat;
  background-position-x: center;
  /* background-position-y: 10px; */
  ${Responsive.mobile} {
    width: 100%;
    height: 169px;
    margin-left: unset;
    margin-right: unset;
  }
  ${Responsive.tablet} {
    width: 49vw;
  }
`
const Iframe = styled.iframe`
  margin-left: 2px;
  margin-right: 3px;
  height: 319px;
  width: calc(33.33333vw - 5px);
  /* object-fit: cover; */
  background-repeat: no-repeat;
  background-position-x: center;
  /* background-position-y: 10px; */
  ${Responsive.mobile} {
    width: 100%;
    height: 210px;
    margin-left: unset;
    margin-right: unset;
  }
  ${Responsive.tablet} {
    width: 49vw;
  }
`
// const BlurhashImg = styled(Blurhash)`
//   margin-left: 2px !important;
//   margin-right: 3px !important;
//   height: 319px !important;
//   width: calc(33.33333vw - 5px) !important;
//   /* object-fit: cover !important; */
//   background-repeat: no-repeat !important;
//   background-position-x: center !important;
//   /* background-position-y: 10px; */
//   ${Responsive.mobile} {
//     width: 100% !important;
//     height: 169px !important;
//     margin-left: unset !important;
//     margin-right: unset !important;
//   }
//   ${Responsive.tablet} {
//     width: 49vw !important;
//   }
// `
const Description = styled.div`
  height: 250px;
  padding-bottom: 30px;
  width: calc(33.33333vw - 5px);
  ${Responsive.mobile} {
    width: 100%;
    height: unset;
    padding-bottom: unset;
  }
  ${Responsive.tablet} {
    width: unset;
  }
`
const Top = styled.div`
  display: flex;
  margin-top: 8px;
`
const Location = styled.h1`
  width: 315px;
  height: 23px;
  margin-left: 44px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  font-feature-settings: 'case' on;
  color: #313131;
  opacity: 0.5;
  ${Responsive.mobile} {
    margin-left: 20px;
    font-size: 15px;
    line-height: 23px;
  }
`
const OpenIconStyle = styled(OpenIcon)`
  margin-right: 20px;
  margin-left: auto;
  margin-top: 10px;
`
const Text = styled.div`
  margin-left: 46px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #313131;
  opacity: 0.8;
  margin-right: 20px;
  ${Responsive.mobile} {
    margin-left: 20px;
    width: 343px;
    /* height: 110px; */
    margin-top: 20px;
    margin-bottom: 30px;
  }

  /* margin-bottom: 25px; */
  /* :last-of-type {
    margin-bottom: 50px;
  } */
`
const Name = styled.h1`
  margin-top: 10px;
  /* max-width: 500px; */
  height: 56px;
  margin-left: 44px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  /* line-height: 48px; */
  font-feature-settings: 'case' on;
  color: #313131;
  ${Responsive.laptop} {
    font-size: 30px;
    /* width: 350px; */
    height: 56px;
  }
  ${Responsive.mobile} {
    margin-top: unset;
    width: 300px;
    height: unset;
    margin-left: 20px;
    font-size: 24px;
    line-height: 29px;
  }
  ${Responsive.tablet} {
    width: 300px;
    font-size: 20px;
    /* width: 350px; */
    height: 36px;
  }
`
const Type = styled.h6`
  margin-top: 20px;
  width: 200px;
  height: 23px;
  margin-left: 44px;
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  font-feature-settings: 'case' on;
  color: #313131;
  font-family: 'FiraGO-Regular' !important;
  padding-bottom: 40px;
  ${Responsive.mobile} {
    margin-top: -10px;
    margin-left: 20px;
    font-size: 15px;
    line-height: 23px;
    width: 200px;
  }
`
const HorizontalCard = styled.div`
  border-right: 1px solid ${Colors.borderGrey};
  width: calc(66.6666vw);
  display: flex;
  margin-left: 2px;
  cursor: pointer;
  ${Responsive.mobile} {
    width: 100%;
    border-right: none;
    display: block;
    margin-left: unset;
  }
  ${Responsive.tablet} {
    border-right: none;
  }
`
const BottomBorder = styled.div`
  margin-top: -1px;
  width: 100vw;
  z-index: -1;
  border-bottom: 1px solid ${Colors.borderGrey};
  margin-bottom: 50px;
  ${Responsive.mobile} {
    margin-bottom: 50px;
  }
`
const Breadcrumbs = styled.span`
  /* margin-left: -10px;
    font-size: 13px;
    font-weight: bolder; */
  display: flex;
  margin-top: 80px;
  position: fixed;
  background-color: rgba(237 237, 237, 0.4);
  width: calc(100vw - 135px);
  height: 20px;
  margin-left: 135px;
  z-index: 200;
`
const BreadcrumbInactive = styled.span`
  margin-left: 10px;
  cursor: pointer;
  margin-top: 3px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  font-feature-settings: 'case' on;
  color: #313131;
  opacity: 0.5;
  ${Responsive.mobile} {
    display: none;
  }
`
const BreadcrumbActive = styled.span`
  margin-top: 3px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: 'case' on;
  color: rgba(49, 49, 49, 1);
  ${Responsive.mobile} {
    display: none;
  }
`
const BreadcrumbArrowStyle = styled(BreadcrumbArrow)`
  margin-top: 4px;
  margin-left: 10px;
  ${Responsive.mobile} {
    display: none;
  }
`
export default Projects
