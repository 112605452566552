import * as React from 'react'
import styled from 'styled-components'

function LinkedinIncon(props: any) {
  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 6a4.5 4.5 0 014.5 4.5v5.25h-3V10.5a1.5 1.5 0 10-3 0v5.25h-3V10.5A4.5 4.5 0 0112 6zM4.5 6.75h-3v9h3v-9zM3 4.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill="#fff"
      />
    </Svg>
  )
}

const Svg = styled.svg`
  padding: 12px;
  background: #ffffff;
  cursor: pointer;
  path {
    fill: #212121;
  }
  border: 1px solid #eeeeee;
  &:hover {
    background: #00b070;
    path {
      fill: #ffffff;
    }
    border: 1px solid #00b070;
  }
`
export default LinkedinIncon
