import * as React from 'react'
import styled from 'styled-components'

function FacebookIcon(props: any) {
  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5 1.5h-2.25A3.75 3.75 0 007.5 5.25V7.5H5.25v3H7.5v6h3v-6h2.25l.75-3h-3V5.25a.75.75 0 01.75-.75h2.25v-3z"
        fill="#212121"
      />
    </Svg>
  )
}

const Svg = styled.svg`
  padding: 12px;
  background: #ffffff;
  cursor: pointer;
  path {
    fill: #212121;
  }
  border: 1px solid #eeeeee;
  &:hover {
    background: #00b070;
    path {
      fill: #ffffff;
    }
    border: 1px solid #00b070;
  }
`
export default FacebookIcon
