import * as React from 'react'

function BreadcrumbArrow(props: any) {
  return (
    <svg
      width={8}
      height={12}
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 1.25L5.25 5 1.5 8.75"
        stroke="#313131"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BreadcrumbArrow
