import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import WelcomeBannerBgTablet from '../assets/img/welcome-banner-tablet-new.svg'
import Responsive from '../assets/config/Responsive'
import Colors from '../assets/styles/Colors'
import LeftArrow from '../assets/JSX/LeftArrow'
import RightArrow from '../assets/JSX/RightArrow'

import 'swiper/css'
import { A11y, Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import DownArrow from '../assets/JSX/DownArrow'
import SendProject from '../assets/components/SendProject'
import { useEffect } from 'react'
import useFetch from '../hooks/useFetch'
import Loader from '../assets/components/Loader'
import cookies from 'js-cookie'
import MainImage from '../assets/img/mainImg.png'
import AutoplayCarousel from '../assets/components/AutoplayCarousel'

// @ts-ignore

const Landing = () => {
  //
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  //

  const currentLanguageCode = cookies.get('i18next') || 'en'

  const { data: projectData, loading: projectDataLoading } =
    useFetch(`/projects?populate=*`)

  const project = projectData?.filter((item: any) => {
    return item?.attributes?.showOnHomepage === true
  })

  const showProject = project?.shift(1)

  const { data } = useFetch(`/main-page-${currentLanguageCode}?populate=*`)

  const navigate = useNavigate()

  const mainLoading = projectDataLoading
  // || loading

  // const brakePoints = {
  //   0: {
  //     // width: 800,
  //     slidesPerView: 2,
  //     spaceBetween: 100,
  //   },
  //   900: {
  //     // width: 880,
  //     slidesPerView: 3,
  //     spaceBetween: 20,
  //   },
  //   1000: {
  //     // width: 1200,
  //     slidesPerView: 4,
  //     spaceBetween: 20,
  //   },
  //   1200: {
  //     // width: 1270,
  //     slidesPerView: 4,
  //     // spaceBetween: 6,
  //   },
  //   1350: {
  //     // width: 1370,
  //     slidesPerView: 5,
  //     // spaceBetween: 6,
  //   },
  // }

  const mainImageUrl =
    process.env.REACT_APP_UPLOAD_URL +
    data?.attributes?.mainImage?.data?.attributes?.url

  const WelcomeSection = styled.section`
    margin-top: 80px;
    /* background-image: url(${mainImageUrl}); */
    background-image: url(${MainImage});
    height: calc(100vh - 80px);
    object-fit: fill;
    background-repeat: no-repeat;
    background-position-x: center;
    /* background-position-y: 10px; */
    background-size: 100vw auto;
    /* background-position-y: 30%; */
    ${Responsive.tablet} {
      /* background-image: url(${WelcomeBannerBgTablet}); */
      background-position-y: 80%;
      background-size: unset;
    }
    ${Responsive.mobile} {
      height: calc(100vh - 65px);
      background-position-x: 1%;
      background-position-y: 85%;
      background-size: unset;
    }
  `

  return mainLoading ? (
    <Loader />
  ) : (
    <>
      <Breadcrumbs />
      {/* Welcobe Section */}
      <WelcomeSection>
        <BackgroundShadow />
        <WelcomeBlock>
          <h1>{data?.attributes.firstSectionHeading}</h1>
          <p>{data?.attributes.firstSectionText}</p>
          <button type="button" onClick={() => navigate('/services')}>
            <span>{data?.attributes.firstSectionButtonName}</span>
          </button>
          {/* <ScrollDown> */}
          <Scroll href="#services">
            <ScrollInside>
              <DownArrowStyle />
            </ScrollInside>
          </Scroll>
          {/* </ScrollDown> */}
        </WelcomeBlock>
      </WelcomeSection>
      {/* END Welcobe Section */}
      <Div id="services"></Div>
      {/* Services Section */}
      <ServicesSection>
        <ServicesBlock>
          <h1
            dangerouslySetInnerHTML={{
              __html: data?.attributes.secondSectionHeading,
            }}
          ></h1>
          <p>{data?.attributes.secondSectionFirstText}</p>
          <p>{data?.attributes.secondSectionSecondText}</p>
          <Buttons>
            <button type="button" onClick={() => navigate('/about-us')}>
              {data?.attributes.secondSectionFirstButtonName}
            </button>
            <button type="button" onClick={() => navigate('/services')}>
              {data?.attributes.secondSectionFirstButtonName}
            </button>
          </Buttons>
        </ServicesBlock>
        <ServicesPanel>
          <Panel onClick={() => navigate('/service/1')}>
            <PanelImg
              src={
                process.env.REACT_APP_UPLOAD_URL +
                data?.attributes?.firstPanelPhoto?.data?.attributes?.url
              }
              alt=""
            />
            <ItemOverlay className="overlay">
              <ItemText
                dangerouslySetInnerHTML={{
                  __html: data?.attributes.firstPanelName,
                }}
              />
            </ItemOverlay>
          </Panel>
          <Panel onClick={() => navigate('/service/2')}>
            <PanelImg
              src={
                process.env.REACT_APP_UPLOAD_URL +
                data?.attributes?.secondPanelPhoto?.data?.attributes?.url
              }
              alt=""
            />
            <ItemOverlay className="overlay">
              <ItemText
                dangerouslySetInnerHTML={{
                  __html: data?.attributes.secondPanelName,
                }}
              />
            </ItemOverlay>
          </Panel>
          <Panel onClick={() => navigate('/service/3')}>
            <PanelImg
              src={
                process.env.REACT_APP_UPLOAD_URL +
                data?.attributes?.thirdPanelPhoto?.data?.attributes?.url
              }
              alt=""
            />
            <ItemOverlay className="overlay">
              <ItemText
                dangerouslySetInnerHTML={{
                  __html: data?.attributes.thirdPanelName,
                }}
              />
            </ItemOverlay>
          </Panel>
          <Panel onClick={() => navigate('/service/4')}>
            <PanelImg
              src={
                process.env.REACT_APP_UPLOAD_URL +
                data?.attributes?.fourthPanelPhoto?.data?.attributes?.url
              }
              alt=""
            />
            <ItemOverlay className="overlay">
              <ItemText
                dangerouslySetInnerHTML={{
                  __html: data?.attributes.fourthPanelName,
                }}
              />
            </ItemOverlay>
          </Panel>
        </ServicesPanel>
      </ServicesSection>
      {/*END Services Section */}

      {/*Why Enovus Section */}
      <WhySection>
        <Heading
          dangerouslySetInnerHTML={{
            __html: data?.attributes.thirdSectionHeading,
          }}
        ></Heading>
        <Description>
          <h6>{data?.attributes.thirdSectionText} </h6>
        </Description>
        <CardRow>
          {data?.attributes?.infoBoxes !== undefined &&
            data?.attributes?.infoBoxes
              .slice(0, 3)
              .map((item: any, index: any) => (
                <Card key={index}>
                  <h1>{item.infoBoxTitle}</h1>
                  <p>{item.infoBoxDescription}</p>
                </Card>
              ))}
        </CardRow>
        <CardRow>
          {data?.attributes?.infoBoxes !== undefined &&
            data?.attributes?.infoBoxes
              .slice(3, 6)
              .map((item: any, index: any) => (
                <Card key={index}>
                  <h1>{item.infoBoxTitle}</h1>
                  <p>{item.infoBoxDescription}</p>
                </Card>
              ))}
        </CardRow>
      </WhySection>
      {/*END Why Enovus Section */}

      <OurProjectsSection>
        <HideInDesktop>
          <h1>{data?.attributes?.fourthSectionHeading}</h1>
          <h6 onClick={() => navigate('/projects')}>
            {data?.attributes?.fourthSectionText}
          </h6>
        </HideInDesktop>
        <ProjectsLeftSide>
          {currentLanguageCode === 'ge' ? (
            <>
              <h6>{showProject?.attributes?.locationGe}</h6>
              <h1>{showProject?.attributes?.titleGe}</h1>
              {showProject?.attributes?.descriptions !== undefined &&
                showProject?.attributes?.descriptions
                  .slice(0, 1)
                  .map((item: any, index: any) => (
                    <p key={index}>{item?.descriptionGe}</p>
                  ))}
            </>
          ) : (
            <>
              <h6>{showProject?.attributes?.locationEn}</h6>
              <h1>{showProject?.attributes?.titleEn}</h1>
              {showProject?.attributes?.descriptions !== undefined &&
                showProject?.attributes?.descriptions
                  .slice(0, 1)
                  .map((item: any, index: any) => (
                    <p key={index}>{item?.descriptionEn}</p>
                  ))}
            </>
          )}
          <SliderNavigation>
            <button onClick={() => navigate(`/project/${showProject?.id}`)}>
              {data?.attributes?.fourthSectionButtonName}
            </button>
            <FirstArrowWrapper>
              <FirstLeftArrowStyle className="swiper-button-prev" />
              <FirstRightArrowStyle className="swiper-button-next" />
            </FirstArrowWrapper>
          </SliderNavigation>
        </ProjectsLeftSide>
        <ProjectsRightSide>
          <OurProjectsText>
            <h1>{data?.attributes?.fourthSectionHeading}</h1>
            <h6 onClick={() => navigate('/projects')}>
              {data?.attributes?.fourthSectionText}
            </h6>
          </OurProjectsText>
          <SwiperStyle
            modules={[Navigation, A11y]}
            spaceBetween={0}
            slidesPerView={1}
            // loop = {true}
            //             effect='coverflow'
            //   // slidesPerView='auto'
            //   coverflowEffect={{
            //     rotate: 0,
            //     stretch: 0,
            //     depth: 100,
            //     modifier: 2,
            //     slideShadows: true
            //   }}
            //   grabCursor={true}
            //   freeMode = {true}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
          >
            <SwiperSlideStyle key={1}>
              {showProject?.attributes?.youtubeLink ? (
                <ProjectIframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${showProject?.attributes?.youtubeLink.slice(
                    -11
                  )}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></ProjectIframe>
              ) : (
                <ProjectImg
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    showProject?.attributes?.mainImage?.data?.attributes?.url
                  }
                  alt=""
                />
              )}
            </SwiperSlideStyle>
            {showProject?.attributes?.otherImages?.data?.map(
              (item: any, index: any) => (
                <SwiperSlideStyle key={index + 1}>
                  {item?.attributes?.mime !== 'video/mp4' ? (
                    <ProjectImg
                      src={
                        process.env.REACT_APP_UPLOAD_URL + item?.attributes?.url
                      }
                      alt=""
                    />
                  ) : (
                    <ProjectVideo
                      src={
                        process.env.REACT_APP_UPLOAD_URL + item?.attributes?.url
                      }
                      controls
                    >
                      Your browser does not support the video tag.
                    </ProjectVideo>
                  )}
                </SwiperSlideStyle>
              )
            )}
          </SwiperStyle>
        </ProjectsRightSide>
      </OurProjectsSection>

      <SendProject />

      <LogoSection>
        <AutoplayCarousel />
        {/* <ArrowWrapper>
          <LeftArrowStyle className="logo-button-prev" />
          <RightArrowStyle className="logo-button-next" />
        </ArrowWrapper> */}
      </LogoSection>
    </>
  )
}
const Heading = styled.h1`
  font-size: 60px;
  text-align: center;
  margin-bottom: 0px;
  font-feature-settings: 'case' on;
  ${Responsive.mobile} {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    font-feature-settings: 'case' on;
    margin-bottom: 20px;
    color: #313131;
  }
`
const Description = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  h6 {
    width: 428px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #313131;
    opacity: 0.5;
    flex: none;
    text-align: center;
  }
  ${Responsive.mobile} {
    display: none;
  }
`
const CardRow = styled.div`
  display: flex;
  justify-content: center;
  :last-of-type {
    ${Responsive.laptop} {
      padding-left: 200px;
      padding-right: 200px;
    }
    ${Responsive.mobile} {
      margin-bottom: 40px;
    }
  }
  ${Responsive.mobile} {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`
const WhySection = styled.section`
  background-color: white;
  /* height: 100vh; */
  margin-bottom: 30px;
  span {
    color: ${Colors.green};
  }
  ${Responsive.mobile} {
    height: unset;
    margin: unset;
  }
`
const Card = styled.div`
  background-color: rgb(217, 217, 217, 0.2);
  width: 600px;
  height: 250px;
  color: ${Colors.black};
  font-size: 30px;
  margin: 8px;
  padding: 25px 40px 20px 40px;
  z-index: 1;
  overflow: hidden;
  text-align: justify;
  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'case' on;
    color: #313131;
    margin-bottom: 0;
  }
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #313131;
    opacity: 0.5;
    width: 100%;
  }
  ${Responsive.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 8px;
    width: unset;
    height: unset;
    background: #f7f7f7;
    padding: 25px;
  }
  ${Responsive.laptop} {
    height: 170px;
  }
  ${Responsive.tablet} {
    width: 300px;
    height: unset;
  }
`
// const ArrowWrapper = styled.div`
//   display: flex;
//   padding-right: 50px;
//   border-right: 1px solid ${Colors.borderGrey};
//   padding: 40px 50px;
//   z-index: 10;
//   ${Responsive.mobile} {
//     display: none;
//   }
// `
// const LeftArrowStyle = styled(LeftArrow)`
//   margin-right: 10px;
//   cursor: pointer;
//   display: flex;
//   /* margin-right: 20px; */
//   transition: all 200ms ease-in-out;
//   /*  */
//   &.swiper-button-disabled {
//     transition: all 200ms ease-in-out;
//     transform: scale(0.9);
//     opacity: 0.5;
//   }
//   transform: scale(1);
// `
// const RightArrowStyle = styled(RightArrow)`
//   margin-right: 10px;
//   cursor: pointer;
//   display: flex;
//   /* margin-right: 20px; */
//   transition: all 200ms ease-in-out;
//   /*  */
//   &.swiper-button-disabled {
//     transition: all 200ms ease-in-out;
//     transform: scale(0.9);
//     opacity: 0.5;
//   }
//   transform: scale(1);
// `
const LogoSection = styled.div`
  /* display: flex; */
  border-top: 1px solid ${Colors.borderGrey};
  border-bottom: 1px solid ${Colors.borderGrey};
  margin-top: 100px;
  margin-bottom: 60px;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
  ${Responsive.mobile} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`
const BackgroundShadow = styled.div`
  position: absolute;
  left: 0;
  height: calc(100vh - 80px);
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
  margin-top: 0;
  ${Responsive.mobile} {
    height: calc(100vh - 65px);
  }
`
const PanelImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`
const Panel = styled.div`
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
  /* width: 20vw; */
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: none;
  color: #fff;
  position: relative;
  z-index: 2;
  /* transition: flex .4s ease; */
  h1 {
    display: none;
  }
  &:hover .overlay {
    opacity: 1;
  }
  &:hover {
    -webkit-transition: background-color 1s ease-in;
    -moz-transition: background-color 1s ease-in;
    -o-transition: background-color 1s ease-in;
    transition: background-color 1s ease-in;
    /* background: rgba(0, 0, 0, 0.4); */

    h1 {
      padding-top: 300px;
      background: rgba(0, 0, 0, 0.4);
      display: block;
      text-align: center;
      width: 100%;
      height: calc(100vh - 80px);
      font-style: normal;
      font-weight: 750;
      font-size: 64px;
      line-height: 77px;
      text-align: center;
      font-feature-settings: 'case' on;
      color: #ffffff;
    }
  }
  ${Responsive.laptop} {
    &:hover {
      /* -webkit-transition: background-color 1s ease-in;
    -moz-transition: background-color 1s ease-in;
    -o-transition: background-color 1s ease-in;
    transition: background-color 1s ease-in; */
      /* background: rgba(0, 0, 0, 0.4); */

      h1 {
        font-size: 35px;
        line-height: 57px;
      }
    }
  }
  ${Responsive.mobile} {
    height: 500px;
    width: 100%;
    &:hover {
      h1 {
        font-style: normal;
        font-weight: 750;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        font-feature-settings: 'case' on;
        color: #ffffff;
        height: 500px;
        padding-top: 300px;
      }
    }
    ${Responsive.tablet} {
      /* width: unset; */
      &:hover {
        h1 {
          font-style: normal;
          font-weight: 750;
          font-size: 16px;
          line-height: 23px;
          text-align: center;
          font-feature-settings: 'case' on;
          color: #ffffff;
          height: 500px;
          padding-top: 300px;
        }
      }
    }
  }
`
const ServicesPanel = styled.div`
  display: flex;
  width: 100%;
  ${Responsive.mobile} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: -20px;
    /* justify-content: center; */
    /* align-items: center; */
  }
  ${Responsive.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: calc(100vh + 120px);
  }
`
const ServicesBlock = styled.div`
  width: 20vw;
  text-align: center;
  /* overflow: hidden; */
  span {
    color: ${Colors.green};
  }
  h1 {
    padding: 40px 40px 30px 40px;
    /* width: 320px; */
    justify-content: center;
    font-style: normal;
    text-align: justify;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    font-feature-settings: 'case' on;
    border-bottom: 1px solid ${Colors.borderGrey};
    color: #313131;
    ${Responsive.laptop} {
      font-size: 25px;
      line-height: 28px;
      padding: 20px 20px 10px 20px;
    }
  }
  p {
    padding: 0px 40px 20px 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #313131;
    /* width: 334px; */
    z-index: 11;
    text-align: justify;
    border-bottom: 1px solid ${Colors.borderGrey};
    ${Responsive.laptop} {
      padding: 0px 20px 10px 20px;
      font-size: 11px;
      line-height: 17px;
      text-align: start;
    }
    ${Responsive.mobile} {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      width: 300px;
      color: #313131;
      text-align: start;
      padding-right: 20px;
    }
    ${Responsive.tablet} {
    }
  }
  /* p:last-of-type {
    margin-bottom: 100px;
    ${Responsive.laptop} {
      margin-bottom: 20px;
  }
  } */
  button:first-of-type {
    background-color: ${Colors.grey};
    color: black;
  }
  button {
    font-family: 'Helvetica_Neue_LT_GEO_55_Roman' !important;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 17px 32px;
    gap: 10px;
    width: 334px;
    height: 50px;
    border-radius: 4px;
    background-color: ${Colors.green};
    outline: none;
    border: 0px;
    color: ${Colors.white};
    font-size: 20px;
    margin-top: 10px;
    line-height: 65px;
    transition: all 200ms ease-in;
    z-index: 11;
    &:hover {
      transition: all 200ms ease-in;
      transform: scale(1.12);
      /* background-color: transparent; */
      /* color: ${Colors.black}; */
      /* border: 1px solid ${Colors.black}; */
    }
    ${Responsive.laptop} {
      border-radius: 4px;
      font-size: 14px;
      margin-top: 20px;
      line-height: 35px;
      width: 200px;
      height: 24px;
      margin-top: 10px;
    }
  }
  ${Responsive.tablet} {
    width: 50vw;
    /* button{
      display: none;
    } */
  }
`
const ServicesSection = styled.section`
  height: calc(100vh + 130px);
  display: flex;
  border-bottom: 1px solid ${Colors.borderGrey};
  ${Responsive.mobile} {
    display: block;
    height: unset;
    border-bottom: none;
  }
  /* ${Responsive.tablet} {
    display: block;
  } */
`

const WelcomeBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;
  height: 55%;
  /* welcome banner span */
  span {
    position: relative;
    svg {
      display: none;
      position: absolute;
      left: 0;
      bottom: -12px;
      width: 99%;
    }
  }
  /* welcome banner titile */
  h1 {
    color: ${Colors.white};
    width: 1400px;
    z-index: 11;
    font-style: normal;
    font-weight: 750;
    font-size: 108px;
    line-height: 110px;
    text-align: center;
    font-feature-settings: 'case' on;
    margin-bottom: 40px;
    letter-spacing: 3px;
  }
  /* welcome baaner description */
  p {
    color: ${Colors.white};
    /* margin-inline: 20px; */
    width: 436px;
    /* height: 46px; */
    z-index: 11;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 80px;
  }
  /* welcome banner button */
  button {
    width: 144px;
    height: 50px;
    border-radius: 2px;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    padding: 17px 32px;
    /* gap: 10px; */
    background-color: ${Colors.green};
    outline: none;
    border: 0px;
    color: ${Colors.white};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    transition: all 200ms ease-in;
    z-index: 11;
    cursor: pointer;
    font-feature-settings: 'case' on;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    font-family: 'Helvetica_Neue_LT_GEO_55_Roman';
    &:hover {
      transition: all 200ms ease-in;
      transform: scale(1.12);
      /* background-color: transparent; */
      /* color: ${Colors.black}; */
      /* border: 1px solid ${Colors.black}; */
    }
    span {
      font-feature-settings: 'case' on;
    }
  }

  ${Responsive.mobile} {
    /* welcome banner titile */
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 68px;
      text-align: center;
      font-feature-settings: 'case' on;

      color: #ffffff;
      width: 336px;
      /* height: 272px; */
      margin-top: 180px;
    }
    /* welcome baaner description */
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      width: 343px;
      height: 44px;
      text-align: center;
      margin-top: 0px;
      color: #ffffff;
      /* margin-bottom: 20px; */
    }
    /* welcome banner button */
    button {
      width: 244px;
      height: 48px;
      font-size: 14px;
      font-family: 'Helvetica_Neue_LT_GEO_55_Roman';
    }
  }
  ${Responsive.laptop} {
    padding-top: 40px;
    h1 {
      margin-bottom: 20px;
      margin-top: 80px;
      font-size: 80px;
      line-height: 80px;
    }
    p {
      margin-bottom: 20px;
      width: 800px;
    }
  }
  ${Responsive.mobile} {
    padding-top: 20px;
  }
  ${Responsive.tablet} {
    h1 {
      font-size: 70px;
      line-height: 88px;
      width: 800px;
    }
    button {
      width: 244px;
    }
  }
`
const OurProjectsSection = styled.section`
  display: flex;
  border-top: 1px solid ${Colors.borderGrey};
  ${Responsive.mobile} {
    display: block;
    margin-bottom: 40px;
  }
`

const ProjectsLeftSide = styled.div`
  width: 426px;
  height: 739px;
  background: #ffffff;
  box-shadow: 2px 0px 7px rgba(52, 52, 52, 0.1);
  padding: 100px 80px 50px 40px;
  border: 1px solid ${Colors.borderGrey};
  h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    font-feature-settings: 'case' on;
    color: #313131;
    opacity: 0.5;
  }
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    font-feature-settings: 'case' on;
    color: #313131;
    width: 320px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #313131;
    opacity: 0.5;
    width: 348px;
  }
  button {
    width: 194px;
    height: 50px;
    border-radius: 2px;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    background-color: ${Colors.green};
    outline: none;
    border: 0px;
    color: ${Colors.white};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    transition: all 200ms ease-in;
    z-index: 11;
    cursor: pointer;
    font-feature-settings: 'case' on;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    font-family: 'Helvetica_Neue_LT_GEO_55_Roman';
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 17px 0px;
    margin-top: 60px;
    &:hover {
      transition: all 200ms ease-in;
      transform: scale(1.12);
      /* background-color: transparent; */
      /* color: ${Colors.black}; */
      /* border: 1px solid ${Colors.black}; */
    }
    span {
      font-feature-settings: 'case' on;
    }
    ${Responsive.mobile} {
      margin-bottom: 30px;
    }
  }
  ${Responsive.laptop} {
    width: 22vw;
    h1 {
      font-size: 30px;
      line-height: 38px;
    }
  }
  ${Responsive.tablet} {
    width: 25vw;
    h1 {
      font-size: 27px;
      line-height: 38px;
    }
    p {
      width: 25vw;
    }
  }
  ${Responsive.mobile} {
    p {
      display: none;
    }
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      font-feature-settings: 'case' on;
      color: #313131;
      margin-top: -20px;
    }
    height: unset;
    padding: 0 20px;
  }
`
const ProjectsRightSide = styled.div`
  display: block;
  h1 {
    /* margin-left: 100px; */
    /* width: 535px; */
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 68px;
    text-align: center;
    font-feature-settings: 'case' on;
    color: #313131;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 0;
  }
  h6 {
    cursor: pointer;
    /* margin-left: 250px; */
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    font-feature-settings: 'case' on;
    color: #313131;
    opacity: 0.8;
    &:hover {
      color: #007047;
    }
  }
  ${Responsive.mobile} {
    width: 100%;
    h1 {
      display: none;
    }
    h6 {
      display: none;
    }
  }
`
const HideInDesktop = styled.div`
  display: none;
  ${Responsive.mobile} {
    display: block;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      font-feature-settings: 'case' on;
      color: #313131;
    }
    h6 {
      cursor: pointer;
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-decoration-line: underline;
      font-feature-settings: 'case' on;
      color: #313131;
      opacity: 0.8;
      text-align: center;
      font-family: 'FiraGO-Regular' !important;
    }
  }
`
const SwiperStyle = styled(Swiper)`
  width: 100vw !important;
  /* margin-left: 200px; */
`
const SwiperLogoSlide = styled(SwiperSlide)`
  margin-top: 40px;
  cursor: pointer;
  &.swiper-slide {
    /* margin-right: 200px !important; */
  }
  svg {
    width: 450px;
    height: 50px;
    /* margin-left: 30px !important;
    margin-right: 30px !important; */
    :not(:hover) {
      g {
        fill: grey;
      }
      path {
        fill: grey;
      }
    }
  }

  ${Responsive.laptop} {
    width: 250px !important;
    &.swiper-slide {
      margin-right: 120px !important;
    }
  }
  ${Responsive.mobile} {
    /* width: 150px !important; */
    &.swiper-slide {
      margin-right: 120px !important;
    }
  }
`
const SwiperSlideStyle = styled(SwiperSlide)`
  width: 100vw !important;
  /* overflow-y: visible; */

  &.swiper-slide {
    scale: 1 !important;
    margin-top: 0px;
    margin-left: -770px;
    margin-right: 770px;
    ${Responsive.laptop} {
      margin-left: -0px;
      margin-right: 0px;
    }
    ${Responsive.mobile} {
      scale: 0.9 !important;
      margin-top: 0px;
      margin-left: -53px;
      margin-right: 53px;
    }
  }
  &.swiper-slide-active {
    opacity: 1;
    scale: 0.9 !important;
    margin-top: 0px;
    z-index: 100;
    margin-left: -100px;
    margin-right: -40px;
    ${Responsive.mobile} {
      margin-left: -70px;
      margin-right: 80px;
      scale: 1 !important;
      :last-of-type {
        text-align: center;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    ${Responsive.laptop} {
      margin-left: -67px;
      margin-right: -550px;
    }
    ${Responsive.tablet} {
      margin-left: -30px;
      margin-right: 470px !important;
    }
  }
`
const Buttons = styled.div`
  text-align: center;
  padding: 40px 0px 0 40px;
  button {
    width: 85%;
    font-size: 14px;
    font-feature-settings: 'case' on;
    font-family: 'Helvetica_Neue_LT_GEO_55_Roman';
  }
  ${Responsive.laptop} {
    button {
      font-size: 11px;
    }
  }
  ${Responsive.mobile} {
    display: none;
  }
`
const ProjectImg = styled.img`
  /* width: 20vw; */
  height: 627px;
  ${Responsive.mobile} {
    height: 217px;
    margin-right: 0;
    /* width: 100%; */
  }
  ${Responsive.laptop} {
    width: 60vw;
    margin-right: 0;
  }
  ${Responsive.tablet} {
    width: 60vw;
    margin-right: 0;
  }
`
const ProjectIframe = styled.iframe`
  /* width: 20vw; */
  height: 627px;
  width: 50%;
  ${Responsive.mobile} {
    height: 217px;
    margin-right: 0;
    width: 100%;
  }
  ${Responsive.laptop} {
    width: 55vw;
    margin-right: 0;
  }
  ${Responsive.tablet} {
    width: 60vw;
    margin-right: 0;
  }
`
const ProjectVideo = styled.video`
  /* width: 20vw; */
  height: 627px;
  ${Responsive.mobile} {
    height: 217px;
    margin-right: 0;
    /* width: 100%; */
  }
  ${Responsive.laptop} {
    width: 60vw;
    margin-right: 0;
  }
  ${Responsive.tablet} {
    width: 60vw;
    margin-right: 0;
  }
`
const ScrollInside = styled.div`
  background: rgba(1, 151, 96, 0.7);
  border-radius: 62px;
  width: 58px;
  height: 58px;
  ${Responsive.mobile} {
    height: 48px;
    width: 48px;
  }
  ${Responsive.laptop} {
    height: 48px;
    width: 48px;
  }
`
const DownArrowStyle = styled(DownArrow)`
  margin-bottom: 15px;
  height: 22px;
  width: 22px;
  ${Responsive.mobile} {
    margin-bottom: 20px;
    height: 18px;
    width: 18px;
  }
  ${Responsive.laptop} {
    margin-bottom: 20px;
    height: 18px;
    width: 18px;
  }
`
const Scroll = styled.a`
  width: 70px;
  height: 70px;
  border-radius: 102px;

  /* position: absolute; */
  background: rgba(1, 151, 96, 0.15);
  z-index: 12;
  cursor: pointer;
  /* height: 60px;
  width: 80px; */
  margin: 0px 0 0 0px;
  line-height: 60px;
  bottom: 30px;
  /* color: #FFF; */
  text-align: center;
  font-size: 70px;
  text-decoration: none;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  -webkit-animation: fade_move_down 2s ease-in-out infinite;
  -moz-animation: fade_move_down 2s ease-in-out infinite;
  animation: fade_move_down 2s ease-in-out infinite;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;

  position: absolute;
  /* width: 24px;
  height: 24px; */

  /*animated scroll arrow animation*/
  @-webkit-keyframes fade_move_down {
    0% {
      -webkit-transform: translate(0, -20px);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 20px);
      opacity: 0;
    }
  }
  @-moz-keyframes fade_move_down {
    0% {
      -moz-transform: translate(0, -20px);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -moz-transform: translate(0, 20px);
      opacity: 0;
    }
  }
  @keyframes fade_move_down {
    0% {
      transform: translate(0, -20px);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translate(0, 20px);
      opacity: 0;
    }
  }
  ${Responsive.mobile} {
    bottom: 10px;
    width: 50px;
    height: 50px;
  }
  ${Responsive.laptop} {
    bottom: 10px;
    width: 50px;
    height: 50px;
  }
`
const Div = styled.div`
  position: absolute;
  z-index: 0;
  top: calc(100vh - 79px);
`
const Breadcrumbs = styled.div`
  margin-top: 80px;
  position: fixed;
  background-color: rgba(237 237, 237, 0.4);
  width: calc(100vw - 135px);
  height: 20px;
  margin-left: 135px;
  z-index: 200;
  h1 {
    font-family: 'Helvetica Neue LT GEO';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    font-feature-settings: 'case' on;
    color: #313131;
    opacity: 0.8;
  }
  ${Responsive.mobile} {
    z-index: 999;
  }
`
const ItemOverlay = styled.div`
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease-in-out;
  opacity: 0;
  color: white;
  font-size: 20px;
  height: 100%;
  text-align: center;
  &:hover {
    opacity: 1;
  }
`
const ItemText = styled.div`
  padding-top: 80px;
  /* background: rgba(0, 0, 0, 0.3); */
  display: block;
  text-align: center;
  width: 100%;
  height: calc(100vh - 80px);
  font-style: normal;
  font-weight: 750;
  font-size: 64px;
  line-height: 77px;
  text-align: center;
  font-feature-settings: 'case' on;
  color: #ffffff;
  overflow: hidden;
  &:hover {
    div {
      margin: 0;
      padding: 0;
      animation: slide-up 0.3s ease-in-out forwards;
      opacity: 0;
    }
    /* animation: slide-down 0.4s ease-in-out backwards; */

    @keyframes slide-up {
      from {
        transform: translateY(40%);
        opacity: 0;
      }
      to {
        transform: translateY(0%);
        opacity: 1;
      }
    }
    div:nth-child(1) {
      animation-delay: 0s;
    }

    div:nth-child(2) {
      animation-delay: 0.2s;
    }

    div:nth-child(3) {
      animation-delay: 0.4s;
    }

    div:nth-child(4) {
      animation-delay: 0.6s;
    }
  }
  ${Responsive.laptop} {
    font-size: 44px;
    line-height: 57px;
  }
  ${Responsive.mobile} {
    width: 100%;
    font-style: normal;
    font-weight: 750;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    font-feature-settings: 'case' on;
    color: #ffffff;
    margin-top: 250px;
  }
  ${Responsive.tablet} {
    font-size: 40px;
    line-height: 47px;
  }
`
const OurProjectsText = styled.div`
  width: 60vw;
  text-align: center;
  h6 {
    font-family: 'FiraGO-Regular' !important;
  }
`
const SliderNavigation = styled.div`
  ${Responsive.mobile} {
    display: flex;
  }
`
const FirstArrowWrapper = styled.div`
  display: flex;
  margin-top: 60px;
  ${Responsive.mobile} {
    margin-left: 30px;
  }
`
const FirstLeftArrowStyle = styled(LeftArrow)`
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  /* margin-right: 20px; */
  transition: all 200ms ease-in-out;
  /*  */
  &.swiper-button-disabled {
    transition: all 200ms ease-in-out;
    transform: scale(0.9);
    opacity: 0.5;
  }
  transform: scale(1);
`
const FirstRightArrowStyle = styled(RightArrow)`
  /* margin-top: 10px; */
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  margin-left: -2px;
  transition: all 200ms ease-in-out;
  /*  */
  &.swiper-button-disabled {
    transform: scale(0.9);
    opacity: 0.5;
    transition: all 200ms ease-in-out;
  }
  transform: scale(1);
`

export default Landing
