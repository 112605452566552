import React from 'react'
import styled from 'styled-components'
// import { UseFormRegisterReturn } from 'react-hook-form'
import Responsive from '../config/Responsive'
import Colors from '../styles/Colors'

const Input: React.FC<{
  id: string
  label: string
  type?: string
  placeholder?: string
  value: any
  required?: boolean
  error?: boolean
  errorText?: string
  className?: string
  onChange: (value: any) => void
  style?: React.CSSProperties
  width?: 'fullWidth' | 'default'
  children?: React.ReactNode
  name?: string
  forSpreadOperator?: boolean
  icon?: boolean
  onBlur?: (value: any) => void
  disabled?: boolean
  onKeyPress?: (value: any) => void
  // formRegistration?: UseFormRegisterReturn
}> = ({
  id,
  label = '',
  type = 'text',
  placeholder,
  value,
  required,
  error = false,
  errorText,
  className,
  onChange,
  style,
  width = 'default',
  children,
  name,
  forSpreadOperator = false,
  icon = false,
  onBlur,
  disabled = false,
  onKeyPress,
  // formRegistration,
}) => {
  //
  //
  return (
    <Wrapper style={style} className={className} $width={width}>
      {label ? (
        <LabelStyle $error={error} htmlFor={`field-${id}`}>
          {label}
        </LabelStyle>
      ) : null}
      {forSpreadOperator === true ? (
        <InputStyle
          $error={error}
          required={required}
          value={value}
          type={type}
          id={`field-${id}`}
          name={name}
          placeholder={placeholder}
          onChange={(value) => onChange(value)}
          onBlur={onBlur}
          disabled={disabled}
          onKeyPress={onKeyPress}
          // {...formRegistration}
        />
      ) : (
        <InputStyle
          $error={error}
          required={required}
          value={value}
          type={type}
          id={`field-${id}`}
          name={name}
          placeholder={placeholder}
          onChange={(event) => onChange(event.target.value)}
          onBlur={onBlur}
          disabled={disabled}
          onKeyPress={onKeyPress}
          // {...formRegistration}
        />
      )}

      {children}
      {error === true ? (
        <ErrorMessage $error={error} className={`field-${id}`}>
          {errorText}
        </ErrorMessage>
      ) : null}
    </Wrapper>
  )
}
//
//
const ErrorMessage = styled.div<{ $error: boolean }>`
  width: inherit;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: ${({ $error }) =>
    $error === true ? `${Colors.primaryRed}` : `${Colors.borderGreen}`};
  position: absolute;
  bottom: -25px;
  left: 0;
  /* z-index: 1; */
`
const LabelStyle = styled.label<{ $error: boolean }>`
  /* width: inherit;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  text-align: left;
  padding-right: 25px;
  */
  color: ${({ $error }) =>
    $error === true ? `${Colors.primaryRed}` : `${Colors.borderGreen}`};
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: 'case' on;
  color: #313131;
  opacity: 0.8;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 5px;
`
const InputStyle = styled.input<{ $error: boolean }>`
  width: inherit;
  outline: none;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid ${Colors.borderGreen};
  border-color: ${({ $error }) =>
    $error === true ? `${Colors.primaryRed}` : `${Colors.borderGrey}`};
  border-radius: 4px;
  background-color: ${Colors.white};
  color: ${Colors.black};
  -webkit-appearance: none;
  -moz-appearance: textfield;

  /* focus stlyes */
  &:focus {
    border-color: ${({ $error }) =>
      $error === true ? `${Colors.primaryRed}` : `${Colors.borderGreen}`};
  }

  /* responsive */
  ${Responsive.tablet} {
    padding: 15px 18px;
  }
  ${Responsive.mobile} {
    padding: 15px 18px;
  }
`
const Wrapper = styled.div<{ $width: string }>`
  /* styles from props */
  width: ${({ $width }) => ($width === 'fullWidth' ? '382px' : '382px')};
  user-select: none;
  position: relative;
  ${Responsive.mobile} {
    width: 95%;
  }
`

export default Input
