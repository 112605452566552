const Colors = {
  white: '#FFFFFF',
  
  black: '#000000',
  secondaryBlack: '#313131',

  green: '#019760',
  lightGreen: '#00B070',
  boldGreen: '#007047',
  activeGreen: '#50A752',

  grey: '#E8E8E8',
  lightGrey: 'rgba(232, 232, 232, 0.5)',
  boldGrey: '#DDDDDD',
  borderGrey: 'rgba(232, 232, 232, 0.5)',

  cyan: 'rgba(0, 176, 112, 0.57);',

  primaryRed: '#EB5757',

  borderGreen: 'rgba(1, 151, 96, 0.3);'
}

export default Colors
