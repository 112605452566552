import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import Colors from '../assets/styles/Colors'

import OpenIcon from '../assets/JSX/OpenIcon'
import Responsive from '../assets/config/Responsive'
import LeftArrow from '../assets/JSX/LeftArrow'
import RightArrow from '../assets/JSX/RightArrow'
import 'swiper/css'
import { A11y, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import BreadcrumbArrow from '../assets/JSX/BreadcrumbArrow'
import { useEffect } from 'react'
import useFetch from '../hooks/useFetch'
import Loader from '../assets/components/Loader'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Project = (item: any) => {
  const navigate = useNavigate()
  //
  const id = useParams().id

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [id])

  const { t } = useTranslation()

  //
  const brakePoints = {
    0: {
      // width: 800,
      slidesPerView: 1,
      spaceBetween: 0,
    },
    900: {
      // width: 880,
      slidesPerView: 3,
      // spaceBetween: 6,
    },
    1000: {
      // width: 1200,
      slidesPerView: 3,
    },
    1200: {
      // width: 1270,
      slidesPerView: 3,
      // spaceBetween: 6,
    },
    1350: {
      // width: 1370,
      slidesPerView: 3,
      // spaceBetween: 6,
    },
    //   1650: {
    //     width: 1870,
    //     slidesPerView: 4,
    //     spaceBetween: 95,
    //   },
  }

  const currentLanguageCode = cookies.get('i18next') || 'en'

  const { data, loading } = useFetch(`/projects?populate=*`)
  const { data: project, loading: loadingProject } = useFetch(
    `/projects/${id}?populate=*`
  )

  const { data: projectsSinglePageData, loading: projectsSinglePageLoading } =
    useFetch(`/projects-single-page-${currentLanguageCode}`)

  const mainLoading = loading || loadingProject || projectsSinglePageLoading

  return (
    <>
      <Breadcrumbs>
        <BreadcrumbInactive onClick={() => navigate('/')}>
          {t('main')}
        </BreadcrumbInactive>
        <BreadcrumbArrowStyle />
        <BreadcrumbInactive onClick={() => navigate('/projects')}>
          {' '}
          {t('projects')}
        </BreadcrumbInactive>
        <BreadcrumbArrowStyle />
        {currentLanguageCode === 'ge' ? (
          <BreadcrumbActive>{project?.attributes.titleGe}</BreadcrumbActive>
        ) : (
          <BreadcrumbActive>{project?.attributes.titleEn}</BreadcrumbActive>
        )}
      </Breadcrumbs>

      <Wrapper>
        <ProjectSection>
          <SwiperTopStyle
            // breakpoints={brakePoints}
            modules={[Navigation, A11y]}
            spaceBetween={40}
            slidesPerView={1}
            // loop = {true}
            // effect='coverflow'
            // // slidesPerView='auto'
            // coverflowEffect={{
            //   rotate: 0,
            //   stretch: 0,
            //   depth: 100,
            //   modifier: 2,
            //   slideShadows: true
            // }}
            // grabCursor={true}
            // freeMode = {true}
            navigation={{
              nextEl: '.project-button-next',
              prevEl: '.project-button-prev',
            }}
          >
            <SwiperSlide key={1}>
              {project?.attributes?.youtubeLink ? (
                <Iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${project?.attributes?.youtubeLink.slice(
                    -11
                  )}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></Iframe>
              ) : (
                <Img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    project?.attributes?.mainImage?.data?.attributes?.url
                  }
                  alt=""
                />
              )}
            </SwiperSlide>
            {project?.attributes?.otherImages?.data?.map(
              (item: any, index: any) => (
                <SwiperSlide key={index + 1}>
                  {item?.attributes?.mime !== 'video/mp4' ? (
                    <Img
                      src={
                        process.env.REACT_APP_UPLOAD_URL + item?.attributes?.url
                      }
                      alt=""
                    />
                  ) : (
                    <Video
                      src={
                        process.env.REACT_APP_UPLOAD_URL + item?.attributes?.url
                      }
                      controls
                    >
                      Your browser does not support the video tag.
                    </Video>
                  )}
                </SwiperSlide>
              )
            )}
          </SwiperTopStyle>
          <MobileArrows>
            <FirstLeftArrowStyle className="project-button-prev" />
            <FirstRightArrowStyle className="project-button-next" />
          </MobileArrows>
          <RightSide>
            {currentLanguageCode === 'ge' ? (
              <>
                <Location>{project?.attributes.locationGe}</Location>
                <Name>{project?.attributes.titleGe}</Name>
                <Type>{project?.attributes.typeGe}</Type>
              </>
            ) : (
              <>
                <Location>{project?.attributes.locationEn}</Location>
                <Name>{project?.attributes.titleEn}</Name>
                <Type>{project?.attributes.typeEn}</Type>
              </>
            )}
            {project?.attributes.descriptions?.map((item: any, index: any) =>
              currentLanguageCode === 'ge' ? (
                <Text key={index}>{item?.descriptionGe}</Text>
              ) : (
                <Text key={index}>{item?.descriptionEn}</Text>
              )
            )}
            <FirstArrowWrapper>
              <FirstLeftArrowStyle className="project-button-prev" />
              <FirstRightArrowStyle className="project-button-next" />
            </FirstArrowWrapper>
          </RightSide>
        </ProjectSection>

        <ProjectWrapper>
          <TitleSection>
            <PageTiTle>
              {projectsSinglePageData?.attributes.otherProjectsHeading}
            </PageTiTle>
            <Arrows>
              <SwiperLeftBtn className="swiper-button-prev">
                <LeftArrow />
              </SwiperLeftBtn>
              <SwiperRightBtn className="swiper-button-next">
                <RightArrow />
              </SwiperRightBtn>
            </Arrows>
          </TitleSection>
          <SwiperStyle
            breakpoints={brakePoints}
            modules={[Navigation, A11y]}
            spaceBetween={6}
            slidesPerView={3}
            // loop = {true}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
          >
            <OtherProjectsSection>
              {data?.map((item: any, index: any) => (
                <SwiperSlideStyle key={index}>
                  <Card
                    key={item.id}
                    onClick={() => navigate(`/project/${item.id}`)}
                  >
                    {item.attributes?.youtubeLink ? (
                      <IframeSmall
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${item.attributes?.youtubeLink?.slice(
                          -11
                        )}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></IframeSmall>
                    ) : (
                      <ImgSmall
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          item.attributes?.mainImage?.data?.attributes?.url
                        }
                        alt=""
                      />
                    )}
                    {currentLanguageCode === 'ge' ? (
                      <Description>
                        <Top>
                          <Location>{item.attributes.locationGe}</Location>
                          <OpenIconStyle />
                        </Top>
                        <Name>{item.attributes.titleGe}</Name>
                        <Type>{item.attributes.typeGe}</Type>
                      </Description>
                    ) : (
                      <Description>
                        <Top>
                          <Location>{item.attributes.locationEn}</Location>
                          <OpenIconStyle />
                        </Top>
                        <Name>{item.attributes.titleEn}</Name>
                        <Type>{item.attributes.typeEn}</Type>
                      </Description>
                    )}
                  </Card>
                </SwiperSlideStyle>
              ))}
            </OtherProjectsSection>
          </SwiperStyle>
          <BottomBorder />
        </ProjectWrapper>
      </Wrapper>
    </>
  )
}
const Wrapper = styled.div`
  margin-top: 120px;
  margin-bottom: -50px;
  ${Responsive.mobile} {
    padding-top: unset;
  }
`
const ProjectSection = styled.section`
  min-height: 719px;
  margin-left: 24px;
  background: #f4f4f4;
  display: flex;
  position: relative;
  ${Responsive.mobile} {
    display: block;
    margin-left: unset;
    padding-bottom: 40px;
  }
  ${Responsive.laptop} {
    min-height: 619px;
  }
`
const Img = styled.img`
  /* position: absolute; */
  /* width: 1358px; */
  width: 75vw;
  height: 681px;
  /* object-fit: cover;
  background-repeat: no-repeat;
  background-position-x: center; */
  margin-left: -24px;
  margin-top: 75px;
  margin-bottom: -15px;
  ${Responsive.laptop} {
    width: 68vw;
    height: 581px;
  }
  ${Responsive.mobile} {
    width: 100%;
    height: 271px;
    margin-left: unset;
    margin-top: unset;
  }
`
const Iframe = styled.iframe`
  /* position: absolute; */
  /* width: 1358px; */
  width: 75vw;
  height: 681px;
  /* object-fit: cover;
  background-repeat: no-repeat;
  background-position-x: center; */
  margin-left: -24px;
  margin-top: 75px;
  margin-bottom: -15px;
  ${Responsive.laptop} {
    width: 68vw;
    height: 581px;
  }
  ${Responsive.mobile} {
    width: 100%;
    height: 271px;
    margin-left: unset;
    margin-top: unset;
  }
`
const Video = styled.video`
  /* position: absolute; */
  /* width: 1358px; */
  width: 100;
  height: 681px;
  /* object-fit: cover;
  background-repeat: no-repeat;
  background-position-x: center; */
  margin-left: -14px;
  margin-top: 75px;
  margin-bottom: -15px;
  ${Responsive.laptop} {
    width: 68vw;
    height: 581px;
  }
  ${Responsive.mobile} {
    width: 100%;
    height: 271px;
    margin-left: unset;
    margin-top: unset;
  }
`
const MobileArrows = styled.div`
  display: none;
  ${Responsive.mobile} {
    display: flex;
    margin-left: 240px;
    margin-top: -25px;
  }
`
const RightSide = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  width: 25vw;
  right: 0;
  left: auto;
  ${Responsive.mobile} {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
`
const FirstArrowWrapper = styled.div`
  display: flex;
  margin-left: 46px;
  position: absolute;
  bottom: -33px;
  ${Responsive.mobile} {
    display: none;
  }
`
const FirstLeftArrowStyle = styled(LeftArrow)`
  margin-right: 10px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  /* margin-right: 20px; */
  transition: all 200ms ease-in-out;
  /*  */
  &.swiper-button-disabled {
    transition: all 200ms ease-in-out;
    transform: scale(0.9);
    opacity: 0.5;
  }
  transform: scale(1);
  ${Responsive.mobile} {
    z-index: 10;
    &.swiper-button-disabled {
      transition: all 200ms ease-in-out;
      transform: scale(0.9);
      opacity: 1;
      background-color: ${Colors.borderGrey};
    }
  }
`
const FirstRightArrowStyle = styled(RightArrow)`
  /* margin-top: 10px; */
  margin-right: 10px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  /* margin-right: 20px; */
  transition: all 200ms ease-in-out;
  /*  */
  &.swiper-button-disabled {
    transition: all 200ms ease-in-out;
    transform: scale(0.9);
    opacity: 0.5;
  }
  transform: scale(1);
  ${Responsive.mobile} {
    z-index: 10;
    &.swiper-button-disabled {
      transition: all 200ms ease-in-out;
      transform: scale(0.9);
      opacity: 1;
      background-color: ${Colors.borderGrey};
    }
  }
`
const ProjectWrapper = styled.div`
  margin-bottom: 100px;
  margin-top: -33px;
  ${Responsive.mobile} {
    margin-left: 4vw;
    margin-right: 4vw;
  }
`
const TitleSection = styled.section`
  width: 33.33333vw;
  text-align: center;
  height: 220px;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid ${Colors.borderGrey};
  border-right: 1px solid ${Colors.borderGrey};
  display: block;
  /* align-items: center;
  justify-content: center; */
  /* margin-left: calc(33.33333vw); */
  ${Responsive.mobile} {
    width: unset;
    border-left: none;
    border-right: none;
    margin-left: unset;
    height: 150px;
  }
`

const PageTiTle = styled.h1`
  /* width: 387px; */
  padding-top: 90px;
  height: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  font-feature-settings: 'case' on;
  color: #313131;
  ${Responsive.mobile} {
    font-size: 30px;
    line-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${Responsive.tablet} {
    font-size: 30px;
    line-height: 38px;
  }
`
const OtherProjectsSection = styled.section`
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  /* display: flex; */
  ${Responsive.mobile} {
    display: block;
  }
`
const Location = styled.h1`
  width: 115px;
  height: 23px;
  margin-left: 44px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  font-feature-settings: 'case' on;
  color: #313131;
  opacity: 0.5;
  ${Responsive.mobile} {
    margin-left: 20px;
    font-size: 15px;
    line-height: 23px;
    width: 100%;
  }
`
const Name = styled.h1`
  margin-top: 10px;
  width: 300px;
  /* height: 100px; */
  margin-left: 44px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  font-feature-settings: 'case' on;
  color: #313131;
  margin-bottom: 20px;
  ${Responsive.laptop} {
    font-size: 30px;
  }
  ${Responsive.mobile} {
    /* height: 100px; */
    margin-top: unset;
    width: 200px;
    height: unset;
    margin-left: 20px;
    font-size: 24px;
    line-height: 29px;
  }
`
const Type = styled.h6`
  margin-top: 0px;
  width: 200px;
  /* width: 106px; */
  height: 23px;
  margin-left: 44px;
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  font-feature-settings: 'case' on;
  color: #313131;
  font-family: 'FiraGO-Regular' !important;
  ${Responsive.mobile} {
    margin-top: -10px;
    margin-left: 20px;
    font-size: 15px;
    line-height: 23px;
    width: 100%;
  }
  ${Responsive.tablet} {
    margin-top: 20px;
  }
`
const Text = styled.p`
  margin-left: 46px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #313131;
  opacity: 0.8;
  margin-bottom: 25px;
  :last-of-type {
    margin-bottom: 50px;
  }
  ${Responsive.laptop} {
    font-size: 11px;
    line-height: 17px;
    margin-bottom: 15px;
  }
  ${Responsive.tablet} {
    margin-bottom: 30px;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  ${Responsive.mobile} {
    margin-left: 20px;
    width: 343px;
    /* height: 110px; */
    margin-top: 20px;
  }
`
const Card = styled.div`
  border-right: 1px solid ${Colors.borderGrey};
  border-bottom: 1px solid ${Colors.borderGrey};
  width: calc(33.33333vw);
  cursor: pointer;
  /* :nth-of-type(3n+0) {
    border-right: none;
  } */
  ${Responsive.mobile} {
    /* width: 100%;
    margin-right: 50px;
    border-left: 1px solid ${Colors.borderGrey};
    border-right: 1px solid ${Colors.borderGrey};
    border-bottom: none; */
    width: 100%;
  }
  ${Responsive.tablet} {
    border-right: none;
  }
`
const ImgSmall = styled.img`
  margin-left: 2px;
  margin-right: 3px;
  height: 319px;
  width: calc(33.33333vw - 5px);
  /* object-fit: cover; */
  background-repeat: no-repeat;
  background-position-x: center;
  :nth-of-type(4n + 0) {
    margin-left: 18px;
  }
  /* background-position-y: 10px; */
  ${Responsive.mobile} {
    width: 100%;
    /* width: 100%;
    height: 169px;
    margin-left: unset;
    margin-right: unset;
    overflow: visible; */
  }
`
const IframeSmall = styled.iframe`
  margin-left: 2px;
  margin-right: 3px;
  height: 319px;
  width: calc(33.33333vw - 5px);
  /* object-fit: cover; */
  background-repeat: no-repeat;
  background-position-x: center;
  :nth-of-type(4n + 0) {
    margin-left: 18px;
  }
  /* background-position-y: 10px; */
  ${Responsive.mobile} {
    width: 100%;
    /* width: 100%;
    height: 169px;
    margin-left: unset;
    margin-right: unset;
    overflow: visible; */
  }
`
const Top = styled.div`
  display: flex;
  margin-top: 8px;
`
const Description = styled.div`
  height: 150px;
  padding: 30px;
  padding-bottom: 80px;
  ${Responsive.mobile} {
    width: 100%;
    height: 169px;
    padding-left: 20px;
  }
`
const OpenIconStyle = styled(OpenIcon)`
  margin-right: 20px;
  margin-left: auto;
  margin-top: 10px;
  ${Responsive.mobile} {
    padding-right: 30px;
  }
`
const BottomBorder = styled.div`
  ${Responsive.mobile} {
    margin-top: -1px;
    width: 100vw;
    z-index: -1;
    border-bottom: 1px solid ${Colors.borderGrey};
    margin-bottom: 50px;
    margin-left: -4vw;
  }
`
const SwiperTopStyle = styled(Swiper)`
  margin-left: -24px;
  width: 68vw;
  ${Responsive.mobile} {
    width: 100%;
    margin-left: 0px;
  }
`
const SwiperStyle = styled(Swiper)`
  :nth-of-type(4n + 0) {
    margin-left: 10px;
  }
`
const Arrows = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  cursor: pointer;
  ${Responsive.tablet} {
    /* display: none; */
    margin-top: 0px;
  }
  ${Responsive.mobile} {
    display: none;
  }
`
const SwiperLeftBtn = styled.div`
  display: flex;
  /* margin-right: 20px; */
  transition: all 200ms ease-in-out;
  /*  */
  &.swiper-button-disabled {
    transition: all 200ms ease-in-out;
    transform: scale(0.8);
    opacity: 0.5;
  }
  transform: scale(1);
`
const SwiperRightBtn = styled.div`
  /* transform: rotate(180deg); */
  display: flex;
  margin-left: 5px;
  transition: all 200ms ease-in-out;
  /*  */
  &.swiper-button-disabled {
    transform: scale(0.8);
    opacity: 0.5;
    transition: all 200ms ease-in-out;
  }
  transform: scale(1);
`
const Breadcrumbs = styled.span`
  /* margin-left: -10px;
    font-size: 13px;
    font-weight: bolder; */
  display: flex;
  margin-top: 80px;
  position: fixed;
  background-color: rgba(237 237, 237, 0.4);
  width: calc(100vw - 135px);
  height: 20px;
  margin-left: 135px;
  z-index: 200;
`
const BreadcrumbInactive = styled.span`
  margin-left: 10px;
  cursor: pointer;
  margin-top: 3px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  font-feature-settings: 'case' on;
  color: #313131;
  opacity: 0.5;
  ${Responsive.mobile} {
    display: none;
  }
`
const BreadcrumbActive = styled.span`
  margin-top: 3px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: 'case' on;
  color: rgba(49, 49, 49, 1);
  ${Responsive.mobile} {
    display: none;
  }
`
const BreadcrumbArrowStyle = styled(BreadcrumbArrow)`
  margin-top: 4px;
  margin-left: 10px;
  :first-of-type {
    opacity: 0.4;
  }
  ${Responsive.mobile} {
    /* margin-top: 5px; */
    display: none;
  }
`
const SwiperSlideStyle = styled(SwiperSlide)`
  margin-right: 3px !important;
  margin-left: -3px !important;
  width: calc(33vw + 5px) !important;
  ${Responsive.mobile} {
    width: 95% !important;
    margin-right: 20px !important;
    &.swiper-slide-active {
      margin-left: 0px;
      margin-right: 0px;
      /* :last-of-type {
        text-align: center;
      } */
    }
  }
`
export default Project
