import { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/JSX/Logo'
import SearchLogo from '../../assets/JSX/SearchLogo'
import LanguageLogo from '../../assets/JSX/LanguageLogo'
import Colors from '../../assets/styles/Colors'
import Responsive from '../../assets/config/Responsive'
import HamburgerLogo from '../../assets/JSX/HamburgerLogo'
import CloseIcon from '../../assets/JSX/CloseIcon'
import CloseSearch from '../../assets/JSX/CloseSearch'
import { Scrollbar } from '../../assets/styles/GlobalStyle'
// import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
// @ts-ignore

const Header = () => {
  const navigate = useNavigate()
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false)
  const [openLanguageMenu, setLanguageMenu] = useState<boolean>(false)

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])
  //

  const { t } = useTranslation()

  const currentLanguageCode = cookies.get('i18next') || 'en'

  // const { t } = useTranslation()
  const changeLanguageToGe = async () => {
    i18next.changeLanguage('ge')
    window.location.reload()
  }
  const changeLanguageToEn = async () => {
    i18next.changeLanguage('en')
    window.location.reload()
  }
  return (
    <>
      <HeaderStyle>
        <TopSection>
          <LogoWrapper onClick={() => navigate('/')}>
            <LogoStyle />
          </LogoWrapper>
          <Ul>
            <Li onClick={() => navigate('/')}>{t('main')}</Li>
            <Li onClick={() => navigate('/about-us')}>{t('company')}</Li>
            <Li onClick={() => navigate('/services')}>{t('services')}</Li>
            <Li onClick={() => navigate('/projects')}>{t('projects')}</Li>
          </Ul>
          <RightSide>
            <AuthButtons>
              <IconSearch>
                <div>
                  <SearchLogoStyle />
                </div>
                <input type="text" placeholder="მოიძიე" />
                <CloseSearchStyle />
              </IconSearch>
              <Icon>
                <LanguageLogoStyle
                  onClick={() => setLanguageMenu(!openLanguageMenu)}
                />
              </Icon>
            </AuthButtons>
            <AuthButtonsMobile>
              <Icon>
                <SearchLogoStyle />
              </Icon>
              <Icon>
                <HamburgerLogoStyle
                  onClick={() => setOpenMobileMenu(!openMobileMenu)}
                />
              </Icon>
            </AuthButtonsMobile>
          </RightSide>
        </TopSection>
        <BottomSection></BottomSection>
      </HeaderStyle>

      <LanguageSwitcher $openLanguageMenu={openLanguageMenu}>
        <h1 onClick={() => changeLanguageToGe()}>Geo</h1>
        <h1 onClick={() => changeLanguageToEn()}>Eng</h1>
      </LanguageSwitcher>

      <Wrapper $openMobileMenu={openMobileMenu}>
        <Scrollbar hideScroll={openMobileMenu} />
        <MobileHeader>
          <TopSection>
            <LogoWrapper>
              <LogoStyle onClick={() => navigate('/')} />
            </LogoWrapper>
            <RightSide>
              <AuthButtons>
                <Icon>
                  <SearchLogoStyle />
                </Icon>
                <Icon>
                  <LanguageLogoStyle />
                </Icon>
              </AuthButtons>
              <AuthButtonsMobile>
                <Icon>
                  <SearchLogoStyle />
                </Icon>
                <Icon>
                  <CloseIconStyle
                    onClick={() => setOpenMobileMenu(!openMobileMenu)}
                  />
                </Icon>
              </AuthButtonsMobile>
            </RightSide>
          </TopSection>
        </MobileHeader>
        <UlMobile onClick={() => setOpenMobileMenu(!openMobileMenu)}>
          <Li onClick={() => navigate('/')}>{t('main')}</Li>
          <Li onClick={() => navigate('/about-us')}>{t('company')}</Li>
          <Li onClick={() => navigate('/services')}>{t('services')}</Li>
          <Li onClick={() => navigate('/projects')}>{t('projects')}</Li>
          <Li>
            {currentLanguageCode === 'ge' ? (
              <MobileLang onClick={() => changeLanguageToEn()}>
                <LanguageLogoStyle /> <LangText>Eng</LangText>
              </MobileLang>
            ) : (
              <MobileLang onClick={() => changeLanguageToGe()}>
                <LanguageLogoStyle /> <LangText>Geo</LangText>
              </MobileLang>
            )}
          </Li>
        </UlMobile>
      </Wrapper>
    </>
  )
}
const Icon = styled.div`
  padding: 26px;
  border-left: 1px solid rgba(232, 232, 232);
  /* &:hover .switcher {
    display: block;
  } */
`
const LanguageLogoStyle = styled(LanguageLogo)`
  cursor: pointer;
  ${Responsive.mobile} {
  }
`
const SearchLogoStyle = styled(SearchLogo)`
  cursor: pointer;
`
const HamburgerLogoStyle = styled(HamburgerLogo)`
  cursor: pointer;
`
const CloseIconStyle = styled(CloseIcon)`
  cursor: pointer;
`
const AuthButtons = styled.div`
  display: flex;
  right: 0;
  left: auto;
  margin-top: -1px;
  ${Responsive.mobile} {
    display: none;
  }
  ${Responsive.tablet} {
    display: none;
  }
`
const AuthButtonsMobile = styled.div`
  display: none;
  ${Responsive.mobile} {
    right: 0;
    left: auto;
    position: absolute;
    margin-top: auto;
    display: flex;
    margin-top: 50px;
  }
  ${Responsive.tablet} {
    right: 0;
    left: auto;
    position: absolute;
    margin-top: auto;
    display: flex;
  }
`
const RightSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0;
  /* margin-left: auto; */
  ${Responsive.mobile} {
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
    margin-bottom: 50px;
  }
`
const HeaderStyle = styled.div`
  position: fixed;
  top: 0;
  margin-bottom: -30px;
  z-index: 13;
  overflow: hidden;
  font-family: 'FiraGO-Regular' !important;
  ${Responsive.mobile} {
    position: fixed;
  }
`
const MobileHeader = styled.div`
  display: none;
  ${Responsive.mobile} {
    display: block;
    position: fixed;
    z-index: 999;
  }
  ${Responsive.tablet} {
    display: block;
    position: fixed;
    z-index: 999;
  }
`

const BottomSection = styled.div`
  background-color: rgba(237, 237, 237);
  margin-left: 135px;
  padding-top: 3px;
  opacity: 0.3;
`
// const RightSide = styled.div`
//   display: flex;
//   justify-content: space-between;
//   float: right;
// `
const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-right: 160px; */
  cursor: pointer;
  padding: 20px 40px;
  background-color: #ededed;
  border-right: 1px solid #d9d9d9;
  height: 100%;
  ${Responsive.mobile} {
    /* padding: 30px 50px; */
  }
`
const LogoStyle = styled(Logo)`
  margin-top: 10px;
  width: 54px;
  height: fit-content;
`
const TopSection = styled.header`
  position: fixed;
  top: 0;
  transition: all 200ms ease-in-out;
  z-index: 10;
  height: 80px;
  background-color: #ededed;
  display: flex;
  /* opacity: 0.4; */
  justify-content: left;
  align-items: center;
  width: 100%;
  /* box-shadow: 0px 1px 5px -4px rgba(0,0,0,0.45);
  -webkit-box-shadow: 0px 1px 5px -4px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 1px 5px -4px rgba(0,0,0,0.45); */
`
// const LeftSide = styled.div`
//   border-right: 1px solid #D9D9D9;
// `
const Ul = styled.ul`
  display: flex;
  margin: 0px;
  list-style: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  ${Responsive.mobile} {
    display: none;
  }
  ${Responsive.tablet} {
    display: none;
  }
`
const UlMobile = styled(Ul)`
  ${Responsive.mobile} {
    display: flex;
    flex-direction: column;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  ${Responsive.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  li {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'case' on;
    color: #313131;
    opacity: 0.8;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 65px;
    :last-of-type {
      text-align: left;
      margin-left: -30px;
    }
  }
`
const Li = styled.li`
  margin-right: 37px;
  text-transform: capitalize;
  color: #313131;
  transition: all 100ms ease-in-out;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.8;
  /* font-family: 'FiraGO'; */

  &:last-child {
    margin-right: 0px;
  }
  &:hover {
    color: ${Colors.cyan};
    transition: all 100ms ease-in-out;
  }
`
const Wrapper = styled.div<{ $openMobileMenu: boolean }>`
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  transition: all 200ms ease-in-out;
  display: none;
  ${Responsive.mobile} {
    display: block;
    position: fixed;
    top: 0;
    left: ${({ $openMobileMenu }) =>
      $openMobileMenu === true ? '0' : '-100vw'};
    transition: all 200ms ease-in-out;
    background-color: ${Colors.grey};
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    justify-content: start;
    background-repeat: no-repeat;
    background-position: right -34px bottom 0px;
    overflow-y: scroll;
    z-index: 998;
  }
  ${Responsive.tablet} {
    display: block;
    position: fixed;
    top: 0;
    left: ${({ $openMobileMenu }) =>
      $openMobileMenu === true ? '0' : '-100vw'};
    transition: all 200ms ease-in-out;
    background-color: ${Colors.grey};
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    justify-content: start;
    background-repeat: no-repeat;
    background-position: right -34px bottom 0px;
    overflow-y: scroll;
    z-index: 998;
  }
`
const LanguageSwitcher = styled.div<{ $openLanguageMenu: boolean }>`
  position: fixed;
  top: 80px;
  right: 0;
  left: auto;
  width: 77px;
  height: 82px;
  background-color: #ededed;
  opacity: 1;
  z-index: 20;
  transition: all 200ms ease-in-out;
  display: ${({ $openLanguageMenu }) =>
    $openLanguageMenu === true ? 'block' : 'none'};
  padding-bottom: 20px;
  :hover {
    display: block;
  }

  h1 {
    font-family: 'FiraGO';
    font-style: italic;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'case' on;
    color: #313131;
    opacity: 0.5;
    left: ${({ $openLanguageMenu }) =>
      $openLanguageMenu === true ? '0' : '-100vw'};
    transition: all 200ms ease-in-out;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    display: block;
    :hover {
      opacity: 1;
    }
    /* :first-of-type {
      opacity: 1;
    } */
  }
`
const IconSearch = styled(Icon)`
  display: flex;
  position: absolute;
  right: 80px;
  input {
    display: none;
  }
  &:hover {
    margin-top: -2px;
    width: 200px;
    input {
      display: block;
      width: 100%;
      border: 0;
      border-bottom: 2px solid $gray;
      outline: 0;
      font-size: 1.3rem;
      color: $white;
      padding: 7px 0;
      background: transparent;
      transition: border-color 0.2s;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      font-feature-settings: 'case' on;
      color: #313131;
      opacity: 0.5;
      margin-left: 20px;
    }
    &:hover :last-child {
      display: block;
      margin-top: 5px;
      cursor: pointer;
    }
    &:hover :first-child {
      margin-top: 0px;
      cursor: pointer;
    }
  }
`
const CloseSearchStyle = styled(CloseSearch)`
  display: none;
`
const MobileLang = styled.div`
  display: flex;
`
const LangText = styled.div`
  margin-left: 10px;
  margin-top: 4px;
`
export default Header
