import { useState } from 'react'
import styled from 'styled-components'
import Responsive from '../config/Responsive'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from './Input'
import useFetch from '../../hooks/useFetch'
import cookies from 'js-cookie'

const SendProject = () => {
  const [yearlyConsumption, setYearlyConsumption] = useState<string>('')
  const [provider, setProvider] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  // yup validations schema
  const PersonalInfoValidationSchema = Yup.object({
    firstName: Yup.string()
      // .min(2, '*სახელი უნდა შეიცავდეს მინიმუმ 2 სიმბოლოს')
      .matches(/^\S*$/, '*წაშალეთ გამოტოვება')
      .required('*სავალდებულო'),
    lastName: Yup.string()
      // .min(2, '*გვარი უნდა შეიცავდეს მინიმუმ 2 სიმბოლოს')
      .matches(/^\S*$/, '*წაშალეთ გამოტოვება')
      .required('*სავალდებულო'),
    // address: Yup.string()
    //   .required('*სავალდებულო'),
    phone: Yup.string()
      .matches(/^\S*$/, '*წაშალეთ გამოტოვება')
      .min(9, '*მობილურის ნომერი უნდა იყოს 9 ნიშნა')
      .max(9, '*მობილურის ნომერი უნდა იყოს 9 ნიშნა')
      .required('*სავალდებულო'),
    // provider: Yup.string()
    //   .required('*სავალდებულო'),
    // price: Yup.string()
    //   .required('*სავალდებულო'),
    // monthlyPay: Yup.string()
    //   .required('*სავალდებულო'),
  })
  // initial values
  const initialValues = {
    phone: phone || '',
    // email: email || '',
    // provider: provider || '',
  }
  // formi state
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: PersonalInfoValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log(values)
    },
  })
  //
  //

  const currentLanguageCode = cookies.get('i18next') || 'en'

  const { data } = useFetch(`/send-project-${currentLanguageCode}?populate=*`)

  //
  return (
    <SendProjectSection>
      <LeftSide>
        <h1>{data?.attributes.sectionHeading}</h1>
        <p>{data?.attributes.sectionText}</p>
      </LeftSide>
      <Form>
        <h1>{data?.attributes.formTitle}</h1>
        <Input
          id="yearlyConsumption"
          label={data?.attributes.formYearlyConsumption}
          type="text"
          placeholder=""
          value={yearlyConsumption}
          name="yearlyConsumption"
          onChange={(value) => [
            setYearlyConsumption(value),
            formik.setFieldValue('yearlyConsumption', value),
          ]}
          onBlur={formik.handleBlur}
          width={'fullWidth'}
          style={{ marginBottom: '22px' }}
        />
        <Input
          id="provider"
          label={data?.attributes.formProvider}
          type="text"
          placeholder=""
          value={provider}
          name="provider"
          onChange={(value) => [
            setProvider(value),
            formik.setFieldValue('provider', value),
          ]}
          // error={formik.errors.provider && formik.touched.provider ? true : false}
          // errorText={formik.errors.provider}
          onBlur={formik.handleBlur}
          width={'fullWidth'}
          style={{ marginBottom: '22px' }}
        />
        <Input
          id="phone"
          label={data?.attributes.formPhone}
          type="text"
          placeholder=""
          value={phone}
          name="phone"
          onChange={(value) => [
            setPhone(value),
            formik.setFieldValue('phone', value),
          ]}
          error={formik.errors.phone && formik.touched.phone ? true : false}
          errorText={formik.errors.phone}
          onBlur={formik.handleBlur}
          width={'fullWidth'}
          style={{ marginBottom: '22px' }}
        />
        <Input
          id="email"
          label={data?.attributes.formEmail}
          type="email"
          placeholder=""
          value={email}
          name="email"
          onChange={(value) => [
            setEmail(value),
            formik.setFieldValue('email', value),
          ]}
          // error={formik.errors.email && formik.touched.email ? true : false}
          // errorText={formik.errors.email}
          onBlur={formik.handleBlur}
          width={'fullWidth'}
          style={{ marginBottom: '22px' }}
        />

        <button>{data?.attributes.formButton}</button>
      </Form>
    </SendProjectSection>
  )
}

const SendProjectSection = styled.section`
  height: 711px;
  background: #f4f4f4;
  padding-left: 300px;
  display: flex;
  ${Responsive.laptop} {
    padding-left: 100px;
  }
  ${Responsive.mobile} {
    display: block;
    padding-left: unset;
    /* height: unset; */
    padding-left: 16px;
    padding-right: 16px;
    /* margin-bottom: 430px; */
  }
  ${Responsive.tablet} {
    display: block;
    padding-left: unset;
    /* height: unset; */
    padding-left: 16px;
    padding-right: 16px;
    /* margin-bottom: 411px; */
  }
`
const LeftSide = styled.div`
  font-style: normal;
  text-align: center;
  width: 496px;
  font-size: 24px;
  padding-right: 300px;
  padding-top: 200px;

  h1 {
    width: 496px;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    font-feature-settings: 'case' on;
    text-align: start;
    color: #313131;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #313131;
    opacity: 0.5;
    text-align: start;
  }
  ${Responsive.laptop} {
    padding-right: 0px;
  }
  ${Responsive.mobile} {
    padding-top: 40px;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      font-feature-settings: 'case' on;
      color: #313131;
      width: 328px;
    }
    p {
      width: 343px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #313131;
      opacity: 0.5;
    }
  }
  ${Responsive.tablet} {
    padding-right: unset;
    padding-top: 100px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    h1 {
      font-size: 40px;
      width: unset;
      text-align: justify;
    }
    p {
      display: none;
    }
  }
`
const Form = styled.div`
  width: 400px;
  height: 520px;
  margin-top: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(150, 150, 150, 0.25);
  padding: 40px 60px 40px 40px;
  h1 {
    font-style: italic;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'case' on;
    color: #313131;
    margin-top: 0px;
    margin-bottom: 30px;
    font-family: 'FiraGO-Regular' !important;
  }
  h6 {
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    font-feature-settings: 'case' on;
    color: #313131;
    opacity: 0.8;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 5px;
  }
  button {
    width: 422px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 17px 32px;
    gap: 10px;
    height: 50px;
    margin-top: 30px;
    background: #019760;
    color: white;
    border-style: none;
    cursor: pointer;
    font-family: 'Helvetica_Neue_LT_GEO_55_Roman';
    font-feature-settings: 'case' on;
    margin-top: 50px;
  }
  ${Responsive.laptop} {
    margin-left: 100px;
  }
  ${Responsive.mobile} {
    position: unset;
    margin-left: auto;
    margin-right: auto;
    padding: 17px 30px;
    height: unset;
    width: unset;
    button {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      font-feature-settings: 'case' on;
      color: #ffffff;
      flex: none;
      order: 0;
      flex-grow: 0;
      width: 100%;
    }
  }
  ${Responsive.tablet} {
    padding: 40px 60px 60px 40px;
    margin-left: auto;
    margin-right: auto;
  }
`
export default SendProject
