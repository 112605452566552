import * as React from 'react'

function CloseSearch(props: any) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        opacity={0.2}
        clipPath="url(#clip0_26_413)"
        stroke="#000"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.938 3.063l-7.876 7.874M10.938 10.938L3.061 3.061" />
      </g>
      <defs>
        <clipPath id="clip0_26_413">
          <path fill="#fff" d="M0 0H14V14H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CloseSearch
