const Fonts = {
    helvetica_Neue_regular: 'helvetica_neueregular',
    helvetica_Neue_medium: 'helvetica_neuemedium',
    helvetica_Neue_bold: 'helvetica_neuebold',
    Helvetica_Neue_LT_GEO_55_roman: 'Helvetica_Neue_LT_GEO_55_Roman',
    Helvetica_Neue_LT_GEO_65: 'Helvetica_Neue_LT_GEO_65',
    Helvetica_Neue_LT_GEO_75_roman: 'Helvetica_Neue_LT_GEO_75_Roman',
    FiraGORegular: "FiraGO-Regular",
    FiraGOMedium: "FiraGO-Medium",
    FiraGOBold: "FiraGO-Bold",
    FiraGOSemiBold: "FiraGO-SemiBold"
};

export default Fonts;