import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Colors from '../assets/styles/Colors'
import Responsive from '../assets/config/Responsive'
import SendProject from '../assets/components/SendProject'
import BreadcrumbArrow from '../assets/JSX/BreadcrumbArrow'
import Loader from '../assets/components/Loader'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Blurhash } from 'react-blurhash'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

const AboutUs = () => {
  const navigate = useNavigate()
  //
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { t } = useTranslation()

  //
  const currentLanguageCode = cookies.get('i18next') || 'en'

  const servicesQueryKey = 'servicesQuery'
  const fetchServices = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/services?populate=*`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      }
    )
    const data = await response.json()
    return data.data
  }

  const { data: servicesData, isLoading: servicesLoading } = useQuery(
    servicesQueryKey,
    fetchServices,
    {
      staleTime: Infinity,
    }
  )

  const queryKey = 'aboutUsPageQuery'

  const fetchPage = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        `/about-us-page-${currentLanguageCode}?populate=*`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      }
    )
    const data = await response.json()
    return data.data
  }

  const { data, isLoading: loading } = useQuery(queryKey, fetchPage, {
    staleTime: Infinity,
  })

  const [cachedImage, setCachedImage] = useState<any>()

  useEffect(() => {
    const cacheImage = () => {
      const image = new Image()
      image.src =
        process.env.REACT_APP_UPLOAD_URL +
        data?.attributes?.firstSectionPhoto?.data?.attributes?.url
      image.onload = () => {
        setCachedImage(image)
      }
    }

    if (!cachedImage) {
      cacheImage()
    }
  }, [data, cachedImage])

  const [cachedImages, setCachedImages] = useState<any>([])

  useEffect(() => {
    const cacheImages = () => {
      servicesData?.map((item: any) => {
        const image = new Image()
        image.src =
          process.env.REACT_APP_UPLOAD_URL +
          item.attributes?.mainImage?.data?.attributes?.url
        // image.onload = () => {}
        cachedImages.push(image)
      })
    }
    cacheImages()
  }, [servicesData])
  const hashes = [
    'LFJS2@~q9Zbw%h4TxaMxIo9ERP?a',
    'L1O|nX0000_39Y0000?H000000Rj',
    'LUFQOQozRiaz.9aeayf6?wofWBkC',
    'LMHDmw~pMxs:4oIqogjY00Iqe.R-',
  ]

  const mainLoading = loading || servicesLoading

  return mainLoading ? (
    <Loader />
  ) : (
    <>
      <Breadcrumbs>
        <BreadcrumbInactive onClick={() => navigate('/')}>
          {t('main')}
        </BreadcrumbInactive>
        <BreadcrumbArrowStyle />
        <BreadcrumbActive> {t('aboutUs')}</BreadcrumbActive>
      </Breadcrumbs>

      <WelcomeSection>
        <MobileImage>
          {!cachedImage ? (
            <MobileHash
              hash="LHDS,ZjE9It0x|WbRht0IXRrs~jx"
              resolutionX={32}
              resolutionY={32}
              punch={1}
              className="pulse-load"
            />
          ) : (
            <MobileMainImg
              // src={
              //   process.env.REACT_APP_UPLOAD_URL +
              //   data?.attributes?.firstSectionPhoto?.data?.attributes?.url
              // }
              src={cachedImage?.src}
              loading="lazy"
              // src={MainImage}
            />
          )}
        </MobileImage>
        <LeftSide>
          <LeftText>
            <HeadingText>{data?.attributes.firstSectiontTitle}</HeadingText>
            <HeadingName>{data?.attributes.firstSectionHeading}</HeadingName>
            <HeadingDescription>
              {data?.attributes.firstSectionText}
            </HeadingDescription>
          </LeftText>
        </LeftSide>
        <BlurhashMain
          hash="LHDS,ZjE9It0x|WbRht0IXRrs~jx"
          resolutionX={32}
          resolutionY={32}
          punch={1}
          className="pulse-load"
        />
        {!cachedImage ? (
          <BlurhashMain
            hash="LHDS,ZjE9It0x|WbRht0IXRrs~jx"
            resolutionX={32}
            resolutionY={32}
            punch={1}
            className="pulse-load"
          />
        ) : (
          <MainImg
            // src={
            //   process.env.REACT_APP_UPLOAD_URL +
            //   data?.attributes?.firstSectionPhoto?.data?.attributes?.url
            // }
            src={cachedImage?.src}
            // src={MainImage}
            alt=""
            loading="lazy"
          />
        )}
      </WelcomeSection>
      <Wrapper>
        <TitleSection>
          <MiddleBorder />
          <PageTiTle>{data?.attributes.secondSectionHeading}</PageTiTle>
        </TitleSection>
        <ServicesSection>
          <Services>
            {servicesData?.map((item: any, index: any) => (
              <Card onClick={() => navigate(`/service/${item.id}`)} key={index}>
                {!cachedImages[index]?.src ? (
                  <BlurhashServices
                    hash={hashes[index]}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                    className="pulse-load"
                  />
                ) : (
                  <Img src={cachedImages[index]?.src} alt="" loading="lazy" />
                )}
                {currentLanguageCode === 'ge' ? (
                  <Description>
                    <Name>{item.attributes.titleGe}</Name>
                  </Description>
                ) : (
                  <Description>
                    <Name>{item.attributes.titleEn}</Name>
                  </Description>
                )}
              </Card>
            ))}
          </Services>
          <Button>
            <Btn onClick={() => navigate('/services')}>
              {data?.attributes.secondSectionButton}
            </Btn>
          </Button>
        </ServicesSection>
      </Wrapper>
      <SendProject />
      <BottomBorder />
    </>
  )
}
const Wrapper = styled.div`
  margin-left: 3vw;
  margin-right: 3vw;
  border-left: 1px solid ${Colors.borderGrey};
  border-right: 1px solid ${Colors.borderGrey};
  /* border-bottom: 1px solid ${Colors.borderGrey}; */
  ${Responsive.mobile} {
    margin-left: 4vw;
    margin-right: 4vw;
  }
`
const WelcomeSection = styled.section`
  width: 100vw;
  margin-top: 120px;
  display: flex;
  ${Responsive.mobile} {
    display: block;
    margin-top: 100px;
  }
`
const MobileMainImg = styled.img`
  /* display: none; */
  ${Responsive.mobile} {
    /* object-fit: cover; */
    background-repeat: no-repeat;
    background-position-x: center;
    /* display: block; */
    height: 271px;
    width: 100vw;
    left: 0;
    background-position-y: 10px;
  }
`
const MobileHash = styled(Blurhash)`
  /* display: none; */
  ${Responsive.mobile} {
    /* object-fit: cover; */
    /* background-repeat: no-repeat;
    background-position-x: center; */
    /* display: block; */
    height: 271px !important;
    width: 100vw !important;
    left: 0 !important;
    background-position-y: 10px !important;
  }
`
const LeftSide = styled.div`
  width: calc(100vw - 50px);
  background: #f4f4f4;
  min-height: 526px;
  white-space: pre-wrap;
  padding-bottom: 20px;
  ${Responsive.mobile} {
    width: 100vw;
    min-height: 436px;
  }
`
const LeftText = styled.div`
  padding-left: 100px;
  padding-top: 50px;
  width: 33vw;
  ${Responsive.mobile} {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    width: unset;
  }
  ${Responsive.tablet} {
    padding-left: 50px;
  }
`
const HeadingText = styled.h6`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height, or 164% */
  font-feature-settings: 'case' on;
  margin-top: 0;
  margin-bottom: 10px;
  color: rgba(49, 49, 49, 0.5);
  ${Responsive.mobile} {
    font-size: 14px;
  }
`
const HeadingName = styled.h1`
  margin-top: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  width: 450px;
  font-feature-settings: 'case' on;
  color: #313131;
  ${Responsive.mobile} {
    font-size: 24px;
    line-height: 29px;
    width: 300px;
  }
  ${Responsive.tablet} {
    font-size: 30px;
    width: 320px;
    line-height: 34px;
  }
`
const HeadingDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(49, 49, 49, 0.8);
  margin-top: 40px;
  ${Responsive.tablet} {
    font-size: 13px;
    line-height: 18px;
  }
`
const MainImg = styled.img`
  width: 50vw;
  height: 500px;
  right: 0;
  margin-top: 50px;
  position: absolute;
  /* object-fit: cover; */
  background-repeat: no-repeat;
  background-position-x: center;
  ${Responsive.mobile} {
    display: none;
  }
`
const TitleSection = styled.section`
  width: calc(47vw - 4px);
  text-align: center;
  height: 180px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(23vw + 2px);
  border-left: 1px solid ${Colors.borderGrey};
  border-right: 1px solid ${Colors.borderGrey};
  ${Responsive.laptop} {
    width: calc(47vw - 3px);
  }
  ${Responsive.mobile} {
    width: unset;
    border-left: none;
    border-right: none;
    margin-left: unset;
    height: 120px;
  }
`
const MiddleBorder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: -1;
  border-right: 1px solid ${Colors.borderGrey};
  position: absolute;
  ${Responsive.mobile} {
    display: none;
  }
`
const PageTiTle = styled.h1`
  /* width: 32vw; */
  height: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  font-feature-settings: 'case' on;
  color: #313131;
  ${Responsive.mobile} {
    font-size: 30px;
    line-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const ServicesSection = styled.section``
const Services = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  ${Responsive.mobile} {
    display: block;
  }
`
const Card = styled.div`
  margin-left: 2px;
  width: calc(23vw);
  cursor: pointer;
  border-right: 1px solid ${Colors.borderGrey};
  :nth-of-type(3n + 0) {
    margin-left: 3px;
  }
  :nth-of-type(4n + 0) {
    border-right: none;
  }
  ${Responsive.mobile} {
    width: 100%;
    margin-left: unset;
    border-right: none;
    :nth-of-type(3n + 0) {
      margin-left: unset;
    }
  }
`
const Img = styled.img`
  height: 578px;
  width: calc(23vw - 5px);
  /* object-fit: cover; */
  background-repeat: no-repeat;
  background-position-x: center;
  /* background-position-y: 10px; */
  ${Responsive.mobile} {
    width: 100%;
    height: 360px;
  }
`
const BlurhashServices = styled(Blurhash)`
  height: 578px !important;
  width: calc(23vw - 5px) !important;
  /* object-fit: cover
  background-repeat: no-repeat;
  background-position-x: center; */
  /* background-position-y: 10px; */
  ${Responsive.mobile} {
    width: 100% !important;
    height: 360px !important;
  }
`
const Description = styled.div`
  height: 150px;
  padding: 30px;
  padding-bottom: 80px;
  ${Responsive.mobile} {
    height: 0px;
  }
`
const Name = styled.h6`
  width: 100%;
  height: 10px;
  left: 76px;
  margin-top: 6px;
  /* font-family: 'FiraGO'; */
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  font-feature-settings: 'case' on;
  color: #575757;
  font-family: 'FiraGO-Regular' !important;
`
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 30px; */
  bottom: 100px;
  /* position: absolute; */
  /* width: 100%; */
  ${Responsive.mobile} {
    width: unset;
    margin-top: 100px;
  }
`
const Btn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 5px 18px;
  gap: 10px;
  position: absolute;
  width: 170px;
  height: 56px;
  background: #019760;
  border-radius: 2px;
  border-style: none;
  cursor: pointer;
  &:hover {
    transition: all 200ms ease-in;
    transform: scale(1.12);
  }
  margin-top: -200px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  /* margin-left: calc(50vw - 141px); */
  color: #ffffff;
  ${Responsive.mobile} {
    width: 90%;
  }
`
const BottomBorder = styled.div`
  width: 100vw;
  z-index: -1;
  border-bottom: 1px solid ${Colors.borderGrey};
  margin-bottom: 100px;
`
const Breadcrumbs = styled.span`
  /* margin-left: -10px;
    font-size: 13px;
    font-weight: bolder; */
  display: flex;
  margin-top: 80px;
  position: fixed;
  background-color: rgba(237 237, 237, 0.4);
  width: calc(100vw - 135px);
  height: 20px;
  margin-left: 135px;
  z-index: 200;
`
const BreadcrumbInactive = styled.span`
  margin-left: 10px;
  cursor: pointer;
  margin-top: 3px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  font-feature-settings: 'case' on;
  color: #313131;
  opacity: 0.5;
  ${Responsive.mobile} {
    display: none;
  }
`
const BreadcrumbActive = styled.span`
  margin-top: 3px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: 'case' on;
  color: rgba(49, 49, 49, 1);
  ${Responsive.mobile} {
    display: none;
  }
`
const BreadcrumbArrowStyle = styled(BreadcrumbArrow)`
  margin-top: 4px;
  margin-left: 10px;
  ${Responsive.mobile} {
    display: none;
  }
`
const MobileImage = styled.div`
  display: none;
  ${Responsive.mobile} {
    display: block;
  }
`
const BlurhashMain = styled(Blurhash)`
  width: 50vw !important;
  height: 500px !important;
  right: 0 !important;
  margin-top: 50px !important;
  position: absolute !important;
  /* object-fit: cover; */
  /* background-repeat: no-repeat;
  background-position-x: center; */
  ${Responsive.mobile} {
    display: none !important;
  }
`

export default AboutUs
