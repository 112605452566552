import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import httpApi from "i18next-http-backend";
import i18next from "i18next";

i18next
  .use(httpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ge'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['localStorage', 'cookie', 'htmlTag', 'path'],
      caches: ['localStorage', 'cookie'],
    },
    react: { useSuspense: false },
    backend: {
      loadPath: '../locales/{{lng}}/translation.json',
    },
  })

export default i18n;
