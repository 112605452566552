import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import './App.css'
import Landing from './screens/Landing'
import Projects from './screens/Projects'
import Layout from './screens/nav/Layout'
import Services from './screens/Services'
import Project from './screens/Project'
import Fonts from './assets/Fonts'
import AboutUs from './screens/AboutUs'
import Service from './screens/Service'
import NotFound404 from './screens/NotFound404'

function App() {
  const lang: string = 'en'

  useEffect(() => {
    if (lang === 'ge') {
      document.body.style.setProperty(
        'font-family',
        `${Fonts.Helvetica_Neue_LT_GEO_55_roman}`,
        'important'
      )
    }
    //
    if (lang === 'en') {
      document.body.style.setProperty(
        'font-family',
        `${Fonts.helvetica_Neue_regular}`,
        'important'
      )
    }
  }, [lang])
  return (
    <BrowserRouter>
      <Routes>
        {/* landing */}
        <Route
          path="/"
          element={
            <Layout>
              <Landing />
            </Layout>
          }
        />
        {/*  */}

        {/*projects*/}
        <Route
          path="/projects"
          element={
            <Layout>
              <Projects />
            </Layout>
          }
        />
        {/*  */}

        {/*services*/}
        <Route
          path="/services"
          element={
            <Layout>
              <Services />
            </Layout>
          }
        />
        {/*  */}

        {/*service single*/}
        <Route
          path="/service/:id"
          element={
            <Layout>
              <Service />
            </Layout>
          }
        />
        {/*  */}

        {/*project single*/}
        <Route
          path="/project/:id"
          element={
            <Layout>
              <Project />
            </Layout>
          }
        />
        {/*  */}

        {/*Company*/}
        <Route
          path="/about-us"
          element={
            <Layout>
              <AboutUs />
            </Layout>
          }
        />
        {/*  */}

        {/*page not found 404 */}
        <Route
          path="*"
          element={
            <Layout>
              <NotFound404 />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
