import * as React from 'react'

function LanguageLogo(props: any) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_26_282)" stroke="#313131" strokeWidth={1.2}>
        <path d="M12 21a9 9 0 100-18 9 9 0 000 18z" strokeMiterlimit={10} />
        <path
          d="M3.516 9h16.968M3.516 15h16.968"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 20.756c2.071 0 3.75-3.92 3.75-8.756S14.071 3.244 12 3.244c-2.071 0-3.75 3.92-3.75 8.756s1.679 8.756 3.75 8.756z"
          strokeMiterlimit={10}
        />
      </g>
      <defs>
        <clipPath id="clip0_26_282">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LanguageLogo
