import * as React from 'react'
import styled from 'styled-components'
import Responsive from '../config/Responsive'

function RightArrow(props: any) {
  return (
    <Svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#E8E8E8" d="M0 0H50V50H0z" />
      <path
        d="M20.5 31.59l.97.91L29.5 25l-8.03-7.5-.97.905L27.555 25 20.5 31.59z"
        fill="#000"
      />
    </Svg>
  )
}

const Svg = styled.svg`
  &:hover {
    path:first-of-type {
      fill: #00b070;
    }
    path:last-of-type {
      fill: white;
    }
  }
  ${Responsive.mobile} {
    &.swiper-button-disabled {
      path:first-of-type {
        fill: #f4f4f4;
      }
      path:last-of-type {
        fill: #bfbfbf;
      }
    }
  }
`

export default RightArrow
