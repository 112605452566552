import React from 'react'
import styled from 'styled-components'
//
//
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, A11y } from 'swiper'

import 'swiper/css/autoplay'
import 'swiper/css'

// @ts-ignore
import atmosfera from '../../assets/img/company-logos/atmosfera.png'
// @ts-ignore
import jaSolar from '../../assets/img/company-logos/jaSolar.png'
// @ts-ignore
import leapton from '../../assets/img/company-logos/leapton.png'
// @ts-ignore
import frosio from '../../assets/img/company-logos/frosio.png'
// @ts-ignore
import huawei from '../../assets/img/company-logos/huawei.png'
// @ts-ignore
import photomate from '../../assets/img/company-logos/photomate.png'
// @ts-ignore
import dmy from '../../assets/img/company-logos/dmy.png'
// @ts-ignore
import jooby from '../../assets/img/company-logos/jooby.png'
// @ts-ignore
import tbcLeasing from '../../assets/img/company-logos/tbcLeasing.png'
// @ts-ignore
import bog from '../../assets/img/company-logos/bog.png'
// @ts-ignore
import tbc from '../../assets/img/company-logos/tbc.png'
// @ts-ignore
import tbcGe from '../../assets/img/company-logos/tbcGe.png'
// @ts-ignore
import procredit from '../../assets/img/company-logos/procredit.png'
// @ts-ignore
import procreditGe from '../../assets/img/company-logos/procreditGe.png'

import cookies from 'js-cookie'

export type CompanyLogo = {
  image: string
  url: string
}

const AutoplayCarousel = () => {
  //
  const currentLanguageCode = cookies.get('i18next') || 'en'

  //
  const companyLogosEn: CompanyLogo[] = [
    {
      image: atmosfera,
      url: 'https://www.atmosfera.ua/',
    },
    {
      image: jaSolar,
      url: 'https://www.jasolar.com/html/en/',
    },
    {
      image: frosio,
      url: 'https://www.frosionext.com/',
    },
    {
      image: photomate,
      url: 'https://www.photomate.eu/',
    },
    {
      image: leapton,
      url: 'http://www.leaptonpv.com/',
    },
    {
      image: jooby,
      url: 'https://jooby.eu/',
    },
    {
      image: huawei,
      url: 'https://solar.huawei.com/eu',
    },
    {
      image: dmy,
      url: 'https://dmyelektronik.com/',
    },
    {
      image: tbcLeasing,
      url: 'https://tbcleasing.ge/',
    },
    {
      image: bog,
      url: 'https://bankofgeorgia.ge/',
    },
    {
      image: tbc,
      url: 'https://www.tbcbank.ge/',
    },
    {
      image: procredit,
      url: 'https://www.procreditbank.ge/',
    },
    {
      image: atmosfera,
      url: 'https://www.atmosfera.ua/',
    },
    {
      image: jaSolar,
      url: 'https://www.jasolar.com/html/en/',
    },
    {
      image: frosio,
      url: 'https://www.frosionext.com/',
    },
    {
      image: photomate,
      url: 'https://www.photomate.eu/',
    },
    {
      image: leapton,
      url: 'http://www.leaptonpv.com/',
    },
    {
      image: jooby,
      url: 'https://jooby.eu/',
    },
    {
      image: huawei,
      url: 'https://solar.huawei.com/eu',
    },
    {
      image: dmy,
      url: 'https://dmyelektronik.com/',
    },
    {
      image: tbcLeasing,
      url: 'https://tbcleasing.ge/',
    },
    {
      image: bog,
      url: 'https://bankofgeorgia.ge/',
    },
    {
      image: tbcGe,
      url: 'https://www.tbcbank.ge/',
    },
    {
      image: procreditGe,
      url: 'https://www.procreditbank.ge/',
    },
  ]
  const companyLogosGe: CompanyLogo[] = [
    {
      image: atmosfera,
      url: 'https://www.atmosfera.ua/',
    },
    {
      image: jaSolar,
      url: 'https://www.jasolar.com/html/en/',
    },
    {
      image: frosio,
      url: 'https://www.frosionext.com/',
    },
    {
      image: photomate,
      url: 'https://www.photomate.eu/',
    },
    {
      image: leapton,
      url: 'http://www.leaptonpv.com/',
    },
    {
      image: jooby,
      url: 'https://jooby.eu/',
    },
    {
      image: huawei,
      url: 'https://solar.huawei.com/eu',
    },
    {
      image: dmy,
      url: 'https://dmyelektronik.com/',
    },
    {
      image: tbcLeasing,
      url: 'https://tbcleasing.ge/',
    },
    {
      image: bog,
      url: 'https://bankofgeorgia.ge/',
    },
    {
      image: tbc,
      url: 'https://www.tbcbank.ge/',
    },
    {
      image: procredit,
      url: 'https://www.procreditbank.ge/',
    },
    {
      image: atmosfera,
      url: 'https://www.atmosfera.ua/',
    },
    {
      image: jaSolar,
      url: 'https://www.jasolar.com/html/en/',
    },
    {
      image: frosio,
      url: 'https://www.frosionext.com/',
    },
    {
      image: photomate,
      url: 'https://www.photomate.eu/',
    },
    {
      image: leapton,
      url: 'http://www.leaptonpv.com/',
    },
    {
      image: jooby,
      url: 'https://jooby.eu/',
    },
    {
      image: huawei,
      url: 'https://solar.huawei.com/eu',
    },
    {
      image: dmy,
      url: 'https://dmyelektronik.com/',
    },
    {
      image: tbcLeasing,
      url: 'https://tbcleasing.ge/',
    },
    {
      image: bog,
      url: 'https://bankofgeorgia.ge/',
    },
    {
      image: tbcGe,
      url: 'https://www.tbcbank.ge/',
    },
    {
      image: procreditGe,
      url: 'https://www.procreditbank.ge/',
    },
  ]
  //
  //
  //
  const companyLogos =
    currentLanguageCode === 'ge' ? companyLogosGe : companyLogosEn
  //
  return (
    <Wrapper>
      <Swiper
        breakpoints={{
          100: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
          640: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 5,
          },
          1024: {
            slidesPerView: 7,
          },
        }}
        modules={[Autoplay, A11y]}
        spaceBetween={100}
        loop={true}
        speed={5000}
        dir="ltr"
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
          // reverseDirection: true,
        }}
      >
        {companyLogos.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{
              backgroundImage: 'url(' + item.image + ')',
              filter: 'grayscale(100%)',
            }}
          >
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <img src={item.image} style={{ opacity: '0' }} />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}
//
//
const Wrapper = styled.div`
  user-select: none;
  height: 60px;

  .swiper {
    height: 100%;
  }

  .swiper-pointer-events {
    height: 60px;
  }

  .swiper-wrapper {
    transition-timing-function: linear;
  }

  .swiper-slide {
    overflow: hidden;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      filter: unset !important;
    }
  }
`
//
export default AutoplayCarousel
