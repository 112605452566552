import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Accordion from '../assets/components/Accordion'
import Loader from '../assets/components/Loader'
import SendProject from '../assets/components/SendProject'
import Responsive from '../assets/config/Responsive'
import BreadcrumbArrow from '../assets/JSX/BreadcrumbArrow'
import useFetch from '../hooks/useFetch'
import cookies from 'js-cookie'

import 'swiper/css'
import { A11y, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'react-i18next'

const Service = () => {
  const navigate = useNavigate()
  //
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  //

  const { t } = useTranslation()

  const id = useParams().id
  const { data, loading } = useFetch(`/services/${id}?populate=*`)

  const currentLanguageCode = cookies.get('i18next') || 'en'

  const { data: servicesSingle, loading: servicesLoading } = useFetch(
    `/services-single-page-${currentLanguageCode}`
  )

  const mainLoading = loading || servicesLoading
  return mainLoading ? (
    <Loader />
  ) : (
    <>
      <Breadcrumbs>
        <BreadcrumbInactive onClick={() => navigate('/')}>
          {t('main')}
        </BreadcrumbInactive>
        <BreadcrumbArrowStyle />
        <BreadcrumbInactive onClick={() => navigate('/services')}>
          {' '}
          {t('services')}
        </BreadcrumbInactive>
        <BreadcrumbArrowStyle />
        {currentLanguageCode === 'ge' ? (
          <BreadcrumbActive>{data?.attributes.titleGe}</BreadcrumbActive>
        ) : (
          <BreadcrumbActive>{data?.attributes.titleEn}</BreadcrumbActive>
        )}
      </Breadcrumbs>
      <Wrapper>
        <FirstSection>
          <LeftSide>
            {currentLanguageCode === 'ge' ? (
              <Heading>{data?.attributes.titleGe}</Heading>
            ) : (
              <Heading>{data?.attributes.titleEn}</Heading>
            )}
            <Title>{servicesSingle?.attributes.firstHeading}</Title>
            <MobileSlider>
              <Swiper
                // breakpoints={brakePoints}
                modules={[Navigation, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                effect="coverflow"
                // slidesPerView='auto'
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  slideShadows: true,
                }}
                grabCursor={true}
                // loop = {true}
                freeMode={true}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }}
              >
                <SwiperSlideStyle key={1}>
                  <MobileImg
                    src={
                      process.env.REACT_APP_UPLOAD_URL +
                      data?.attributes?.mainImage?.data?.attributes?.url
                    }
                    alt=""
                  />
                </SwiperSlideStyle>
                {data?.attributes?.otherImages?.data?.map(
                  (item: any, index: any) => (
                    <SwiperSlideStyle key={index + 1}>
                      <MobileImg
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          item?.attributes?.url
                        }
                        alt=""
                      />
                    </SwiperSlideStyle>
                  )
                )}
              </Swiper>
            </MobileSlider>
            {data?.attributes.accordion?.map((item: any, index: any) =>
              currentLanguageCode === 'ge' ? (
                <AccordionStyle
                  openAccordion={true}
                  title={item.topicGe}
                  key={index}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.descriptionGe,
                    }}
                  />
                </AccordionStyle>
              ) : (
                <AccordionStyle
                  openAccordion={true}
                  title={item.topicEn}
                  key={index}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.descriptionEn,
                    }}
                  />
                </AccordionStyle>
              )
            )}
            <Title>{servicesSingle?.attributes.secondHeading}</Title>
            {currentLanguageCode === 'ge' ? (
              <Text
                dangerouslySetInnerHTML={{
                  __html: data?.attributes.summaryTextGe,
                }}
              />
            ) : (
              <Text
                dangerouslySetInnerHTML={{
                  __html: data?.attributes.summaryTextEn,
                }}
              />
            )}
          </LeftSide>
          <RightSide>
            <Swiper
              // breakpoints={brakePoints}
              modules={[Navigation, A11y]}
              spaceBetween={0}
              slidesPerView={1}
              effect="coverflow"
              // slidesPerView='auto'
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2,
                slideShadows: true,
              }}
              grabCursor={true}
              // loop = {true}
              freeMode={true}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
            >
              <SwiperSlideStyle key={1}>
                <Img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    data?.attributes?.mainImage?.data?.attributes?.url
                  }
                  alt=""
                />
              </SwiperSlideStyle>
              {data?.attributes?.otherImages?.data?.map(
                (item: any, index: any) => (
                  <SwiperSlideStyle key={index + 1}>
                    <Img
                      src={
                        process.env.REACT_APP_UPLOAD_URL + item?.attributes?.url
                      }
                      alt=""
                    />
                  </SwiperSlideStyle>
                )
              )}
            </Swiper>
          </RightSide>
        </FirstSection>
        <SendProject />
      </Wrapper>
    </>
  )
}
const Wrapper = styled.div`
  margin-bottom: 100px;
  margin-top: -80px;
`

const FirstSection = styled.div`
  margin-top: 120px;
  display: flex;
`
const LeftSide = styled.div`
  width: 50vw;
  ${Responsive.mobile} {
    width: 100%;
  }
`
const Heading = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  font-feature-settings: 'case' on;
  color: #313131;
  padding-top: 70px;
  padding-left: 40px;
  padding-bottom: 20px;
  ${Responsive.mobile} {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    font-feature-settings: 'case' on;
    color: #313131;
    margin-bottom: -10px;
  }
`
const Title = styled.h3`
  padding-top: 20px;
  padding-left: 40px;
  font-style: italic;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  font-feature-settings: 'case' on;
  color: #575757;
  font-family: 'FiraGO-Regular' !important;
  ${Responsive.mobile} {
    font-style: italic;
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    font-feature-settings: 'case' on;
    color: #575757;
    width: 92px;
    margin-top: 0px;
    margin-bottom: 0;
  }
`
const Text = styled.div`
  padding-top: 20px;
  padding-left: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #313131;
  opacity: 0.8;
  width: 446px;
  white-space: pre-wrap;
  :last-of-type {
    padding-bottom: 60px;
  }
  ${Responsive.mobile} {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #313131;
    opacity: 0.8;
    width: 320px;
  }
`
const RightSide = styled.div`
  width: 50vw;
  ${Responsive.mobile} {
    display: none;
  }
`
const MobileSlider = styled.div`
  display: none;
  ${Responsive.mobile} {
    margin-top: 30px;
    display: block;
    /* width: 341px; */
    height: 360px;
  }
`
const MobileImg = styled.img`
  width: 341px;
  height: 360px;
  /* object-fit: cover; */
  background-repeat: no-repeat;
  background-position-x: center;
`
const Img = styled.img`
  width: calc(30vw + 50px);
  height: 80vh;
  margin-top: 60px;
`
const Breadcrumbs = styled.span`
  /* margin-left: -10px;
    font-size: 13px;
    font-weight: bolder; */
  display: flex;
  margin-top: 80px;
  position: fixed;
  background-color: rgba(237 237, 237, 0.4);
  width: calc(100vw - 135px);
  height: 20px;
  margin-left: 135px;
  z-index: 200;
`
const BreadcrumbInactive = styled.span`
  margin-left: 10px;

  margin-top: 3px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  font-feature-settings: 'case' on;
  color: #313131;
  opacity: 0.5;
  ${Responsive.mobile} {
    display: none;
  }
`
const BreadcrumbActive = styled.span`
  margin-top: 3px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: 'case' on;
  color: rgba(49, 49, 49, 1);
  ${Responsive.mobile} {
    display: none;
  }
`
const BreadcrumbArrowStyle = styled(BreadcrumbArrow)`
  margin-top: 4px;
  margin-left: 10px;
  :first-of-type {
    opacity: 0.4;
  }
  ${Responsive.mobile} {
    display: none;
  }
`
const SwiperSlideStyle = styled(SwiperSlide)`
  &.swiper-slide {
    opacity: 0.4;
    scale: 0.8;
    margin-top: 20px;
    margin-left: -492px;
    margin-right: 492px;
    ${Responsive.laptop} {
      margin-left: -292px;
      margin-right: 292px;
    }
    ${Responsive.mobile} {
      scale: 0.9;
      margin-top: 0px;
      margin-left: -53px;
      margin-right: 53px;
    }
  }
  &.swiper-slide-active {
    opacity: 1;
    scale: 1;
    margin-top: 0px;
    z-index: 100;
    margin-left: 0px;
    margin-right: 0px;
    ${Responsive.mobile} {
      :last-of-type {
        text-align: center;
      }
    }
  }
`
const AccordionStyle = styled(Accordion)``

export default Service
