import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Colors from '../assets/styles/Colors'

import Responsive from '../assets/config/Responsive'
import BreadcrumbArrow from '../assets/JSX/BreadcrumbArrow'
import { useEffect, useState } from 'react'
import Loader from '../assets/components/Loader'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Blurhash } from 'react-blurhash'

const Services = () => {
  const navigate = useNavigate()
  //
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  //

  const { t } = useTranslation()

  const currentLanguageCode = cookies.get('i18next') || 'en'

  const secondKey = 'secondQuery'
  const fetchServicesData = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/services-page-${currentLanguageCode}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      }
    )
    const data = await response.json()
    return data.data
  }

  const { data: serviceData, isLoading: serviceLoading } = useQuery(
    secondKey,
    fetchServicesData,
    {
      staleTime: 1000,
    }
  )

  const firstKey = 'firstQuery'

  const fetchServicePage = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/services?populate=*`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      }
    )
    const data = await response.json()
    return data.data
  }

  const { data: servicesData, isLoading } = useQuery(
    firstKey,
    fetchServicePage,
    {
      staleTime: Infinity,
    }
  )

  const [cachedImages, setCachedImages] = useState<any>([])

  useEffect(() => {
    const cacheImages = () => {
      servicesData?.map((item: any) => {
        const image = new Image()
        image.src =
          process.env.REACT_APP_UPLOAD_URL +
          item.attributes?.mainImage?.data?.attributes?.url
        image.onload = () => {}
        cachedImages.push(image)
      })
    }
    cacheImages()
  }, [servicesData])
  const hashes = [
    'LFJS2@~q9Zbw%h4TxaMxIo9ERP?a',
    'L1O|nX0000_39Y0000?H000000Rj',
    'LUFQOQozRiaz.9aeayf6?wofWBkC',
    'LMHDmw~pMxs:4oIqogjY00Iqe.R-',
  ]

  const mainLoading = isLoading || serviceLoading

  return mainLoading ? (
    <Loader />
  ) : (
    <>
      <Breadcrumbs>
        <BreadcrumbInactive onClick={() => navigate('/')}>
          {t('main')}
        </BreadcrumbInactive>
        <BreadcrumbArrowStyle />
        <BreadcrumbActive> {t('services')}</BreadcrumbActive>
      </Breadcrumbs>

      <Wrapper>
        <TitleSection>
          <MiddleBorder />
          <PageTiTle>{serviceData?.attributes.pageHeading}</PageTiTle>
        </TitleSection>
        <ServicesSection>
          {servicesData?.map((item: any, index: any) =>
            currentLanguageCode === 'ge' ? (
              <Card onClick={() => navigate(`/service/${item.id}`)} key={index}>
                {!cachedImages[index]?.src ? (
                  <BlurhashServices
                    hash={hashes[index]}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                    className="pulse-load"
                  />
                ) : (
                  <Img src={cachedImages[index]?.src} alt="" loading="lazy" />
                )}
                <Description>
                  <Name>{item.attributes.titleGe}</Name>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: item.attributes.summaryTextGe,
                    }}
                  />
                  <Btn>
                    <span>{t('learnMore')}</span>
                  </Btn>
                </Description>
              </Card>
            ) : (
              <Card onClick={() => navigate(`/service/${item.id}`)} key={index}>
                {!cachedImages[index]?.src ? (
                  <BlurhashServices
                    hash={hashes[index]}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                    className="pulse-load"
                  />
                ) : (
                  <Img src={cachedImages[index]?.src} alt="" loading="lazy" />
                )}
                <Description>
                  <Name>{item.attributes.titleEn}</Name>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: item.attributes.summaryTextEn,
                    }}
                  />
                  <Btn>
                    <span>{t('learnMore')}</span>
                  </Btn>
                </Description>
              </Card>
            )
          )}
        </ServicesSection>
      </Wrapper>
      <BottomBorder />
    </>
  )
}
const Wrapper = styled.div`
  margin-left: 3vw;
  margin-right: 3vw;
  border-left: 1px solid ${Colors.borderGrey};
  border-right: 1px solid ${Colors.borderGrey};
  /* border-bottom: 1px solid ${Colors.borderGrey}; */
  ${Responsive.mobile} {
    margin-left: 4vw;
    margin-right: 4vw;
  }
`
const TitleSection = styled.section`
  width: calc(47vw - 4px);
  text-align: center;
  /* height: 260px; */
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(23vw + 2px);
  border-left: 1px solid ${Colors.borderGrey};
  border-right: 1px solid ${Colors.borderGrey};
  margin-top: 80px;
  padding-bottom: 30px;
  h1 {
    margin-top: 60px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    font-feature-settings: 'case' on;
    color: #313131;
  }
  ${Responsive.laptop} {
    width: calc(47vw - 3px);
  }
  ${Responsive.mobile} {
    width: unset;
    border-left: none;
    border-right: none;
    margin-left: unset;
    height: 200px;
  }
`
const MiddleBorder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: -1;
  border-right: 1px solid ${Colors.borderGrey};
  position: absolute;
  ${Responsive.mobile} {
    display: none;
  }
`
const PageTiTle = styled.h1`
  /* width: 32vw; */
  width: 500px;
  height: 48px;
  padding-top: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  font-feature-settings: 'case' on;
  color: #313131;
  ${Responsive.mobile} {
    font-size: 30px;
    line-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const ServicesSection = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  ${Responsive.mobile} {
    display: block;
  }
  ${Responsive.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`
const Card = styled.div`
  margin-left: 2px;
  width: calc(23vw);
  cursor: pointer;
  border-right: 1px solid ${Colors.borderGrey};
  :nth-of-type(3n + 0) {
    margin-left: 3px;
  }
  :nth-of-type(4n + 0) {
    border-right: none;
  }
  ${Responsive.mobile} {
    width: 100%;
    margin-left: unset;
    border-right: none;
    :nth-of-type(3n + 0) {
      margin-left: unset;
    }
  }
  ${Responsive.tablet} {
    width: 46vw;
  }
`
const Img = styled.img`
  height: 578px;
  width: calc(23vw - 5px);
  object-fit: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  /* background-position-y: 10px; */
  ${Responsive.mobile} {
    width: 100%;
    height: 360px;
  }
  ${Responsive.tablet} {
    width: 46vw;
  }
`
const BlurhashServices = styled(Blurhash)`
  height: 578px !important;
  width: calc(23vw - 5px) !important;
  /* object-fit: cover !important; */
  background-repeat: no-repeat !important;
  background-position-x: center !important;
  /* background-position-y: 10px; */
  ${Responsive.mobile} {
    width: 100% !important;
    height: 360px !important;
  }
  ${Responsive.tablet} {
    width: 46vw !important;
  }
`
const Description = styled.div`
  /* height: 300px; */
  padding: 30px;
  padding-bottom: 60px;
  p {
    padding-bottom: 0px;
    height: 200px;
  }
  ${Responsive.laptop} {
    p {
      height: 250px;
      padding-bottom: 40px;
    }
  }
  ${Responsive.mobile} {
    height: unset;
    /* padding-bottom: 120px; */
    h1 {
      font-style: italic;
      font-weight: 700;
      font-size: 17px;
      line-height: 23px;
      font-feature-settings: 'case' on;
      color: #575757;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #313131;
      opacity: 0.8;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
    button {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      font-feature-settings: 'case' on;
      font-family: 'Helvetica_Neue_LT_GEO_55_Roman';
      color: #313131;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
`
const Name = styled.h6`
  font-style: italic;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  font-feature-settings: 'case' on;
  color: #575757;
  overflow: hidden;
  font-family: 'FiraGO-Regular' !important;
  height: 40px;
`
const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #313131;
  opacity: 0.8;
  white-space: pre-wrap;
  overflow-y: hidden;
  height: 115px !important;
  ${Responsive.laptop} {
    font-size: 13px;
    line-height: 20px;
    height: 105px !important;
  }
  ${Responsive.mobile} {
    height: unset;
  }
`
const Btn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* align-items: flex-start;
  padding: 15px 28px; */
  gap: 10px;
  /* position: absolute; */
  width: 131px;
  height: 46px;
  /* margin-top: 67px; */
  background: #e8e8e8;
  border-radius: 2px;
  border-style: none;
  cursor: pointer;
  font-family: 'Helvetica_Neue_LT_GEO_55_Roman';
  &:hover {
    transition: all 200ms ease-in;
    transform: scale(1.12);
  }
  ${Responsive.mobile} {
    margin-top: 20px;
    justify-content: center;
    span {
      width: 131px;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      font-feature-settings: 'case' on;
      color: #313131;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
  ${Responsive.tablet} {
    /* margin-top: 310px; */
  }
`
const BottomBorder = styled.div`
  margin-top: -1px;
  width: 100vw;
  z-index: -1;
  border-bottom: 1px solid ${Colors.borderGrey};
  margin-bottom: 100px;
  ${Responsive.mobile} {
    margin-bottom: 0;
  }
`
const Breadcrumbs = styled.span`
  /* margin-left: -10px;
    font-size: 13px;
    font-weight: bolder; */
  display: flex;
  margin-top: 80px;
  position: fixed;
  background-color: rgba(237 237, 237, 0.4);
  width: calc(100vw - 135px);
  height: 20px;
  margin-left: 135px;
  z-index: 200;
`
const BreadcrumbInactive = styled.span`
  margin-left: 10px;
  cursor: pointer;
  margin-top: 3px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  font-feature-settings: 'case' on;
  color: #313131;
  opacity: 0.5;
  ${Responsive.mobile} {
    display: none;
  }
`
const BreadcrumbActive = styled.span`
  margin-top: 3px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: 'case' on;
  color: rgba(49, 49, 49, 1);
  ${Responsive.mobile} {
    display: none;
  }
`
const BreadcrumbArrowStyle = styled(BreadcrumbArrow)`
  margin-top: 4px;
  margin-left: 10px;
  ${Responsive.mobile} {
    display: none;
  }
`

export default Services
