import * as React from 'react'
import styled from 'styled-components'

function YoutubeIcon(props: any) {
  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.905 2.815a2.084 2.084 0 00-1.455-1.5C14.16 1 9 1 9 1s-5.16 0-6.45.345a2.085 2.085 0 00-1.455 1.5A21.75 21.75 0 00.75 6.813a21.75 21.75 0 00.345 3.997 2.085 2.085 0 001.455 1.44c1.29.345 6.45.345 6.45.345s5.16 0 6.45-.345a2.085 2.085 0 001.455-1.5c.234-1.3.35-2.617.345-3.937a21.736 21.736 0 00-.345-3.998z"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.313 9.265l4.312-2.453L7.312 4.36v4.905z"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

const Svg = styled.svg`
  padding: 12px;
  background: #ffffff;
  cursor: pointer;
  path {
    stroke: #212121;
  }
  border: 1px solid #eeeeee;
  &:hover {
    background: #00b070;
    path {
      stroke: #ffffff;
    }
    border: 1px solid #00b070;
  }
`

export default YoutubeIcon
