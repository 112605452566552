import * as React from 'react'

function HamburgerLogo(props: any) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#clip0_175_1108)"
        stroke="#000"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3.125 10h13.75M3.125 5h13.75M3.125 15h13.75" />
      </g>
      <defs>
        <clipPath id="clip0_175_1108">
          <path fill="#fff" d="M0 0H20V20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default HamburgerLogo
