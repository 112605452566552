import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Responsive from '../../assets/config/Responsive'
import FacebookIcon from '../../assets/JSX/FacebookIcon'
import LinkedinIcon from '../../assets/JSX/LinkedinIcon'
import YoutubeIcon from '../../assets/JSX/YoutubeIcon'
import Colors from '../../assets/styles/Colors'
import useFetch from '../../hooks/useFetch'
import cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import InstagramIcon from '../../assets/JSX/InstagramIcon'
import WhatsappIcon from '../../assets/JSX/WhatsappIcon'

const Footer = () => {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const currentLanguageCode = cookies.get('i18next') || 'en'

  const { data } = useFetch(`/footer-${currentLanguageCode}`)

  const phoneHref = 'tel:' + data?.attributes.mobileNumber

  const emailHref = 'mailto:' + data?.attributes.emailAddress

  return (
    <>
      <TopBorder />
      <Wrapper>
        <Ul>
          <Heading>{data?.attributes.firstSectionTitle}</Heading>
          <p>{data?.attributes.firstSectionText}</p>
          <LinkText href={emailHref}>{data?.attributes.emailAddress}</LinkText>
          <LinkText href={phoneHref}>{data?.attributes.mobileNumber}</LinkText>
          <LinkText href={data?.attributes.locationUrl}>
            {data?.attributes.locationText}
          </LinkText>
        </Ul>
        <Ul>
          <Heading>{data?.attributes.secondSectionTitle}</Heading>
          <Li onClick={() => navigate('/about-us')}>{t('aboutUs')}</Li>
          <Li onClick={() => navigate('/services')}>{t('services')}</Li>
          <Li onClick={() => navigate('/projects')}>{t('projects')}</Li>
        </Ul>
        <Ul>
          <Heading>{data?.attributes.thirdSectionTitle}</Heading>
          <Li onClick={() => navigate('/service/1')}>{t('sun')}</Li>
          <Li onClick={() => navigate('/service/2')}>{t('led')}</Li>
          <Li onClick={() => navigate('/service/3')}>{t('wind')}</Li>
          <Li onClick={() => navigate('/service/4')}>{t('hydro')}</Li>
        </Ul>
        <Ul>
          <Heading>{data?.attributes.fourthSectionTitle}</Heading>
          <SocialMedia>
            <a
              href={data?.attributes.facebookUrl}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIconStyle />
            </a>
            <a
              href={data?.attributes.linkedinUrl}
              target="_blank"
              rel="noreferrer"
            >
              <LinkedinIconStyle />
            </a>
            <a
              href={data?.attributes.youtubeUrl}
              target="_blank"
              rel="noreferrer"
            >
              <YoutubeIconStyle />
            </a>
            <a
              href={data?.attributes.instagramUrl}
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIconStyle />
            </a>
            <a
              href={data?.attributes.whatsappUrl}
              target="_blank"
              rel="noreferrer"
            >
              <WhatsappIconStyle />
            </a>
          </SocialMedia>
        </Ul>
      </Wrapper>
    </>
  )
}
const FacebookIconStyle = styled(FacebookIcon)`
  margin-right: 10px;
`
const LinkedinIconStyle = styled(LinkedinIcon)`
  margin-right: 10px;
`
const YoutubeIconStyle = styled(YoutubeIcon)`
  margin-right: 10px;
`
const InstagramIconStyle = styled(InstagramIcon)`
  margin-right: 10px;
`
const WhatsappIconStyle = styled(WhatsappIcon)`
  margin-right: 10px;
`
const Heading = styled.h3`
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  font-feature-settings: 'case' on;
  font-family: 'FiraGO-Regular' !important;
`
const Li = styled.li`
  margin-top: 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #313131;
  &:hover {
    color: ${Colors.cyan};
  }
`
const LinkText = styled.a`
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #313131;
  text-decoration: none;
  display: block;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 2vw;
  margin-right: 2vw;
  ${Responsive.mobile} {
    display: block;
  }
`
const Ul = styled.ul`
  width: 23vw;
  text-align: left;
  border-left: 1px solid ${Colors.borderGrey};
  margin-top: 0;
  :last-of-type {
    border-right: 1px solid ${Colors.borderGrey};
  }
  :first-of-type {
    padding-left: 30px;
    padding-right: 30px;
  }
  padding-bottom: 100px;
  list-style-type: none;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #313131;
    opacity: 0.5;
  }
  ${Responsive.mobile} {
    padding-left: 30px;
    padding-right: 30px;
    border-left: none;
    width: 80%;
    :first-of-type {
      margin-left: unset;
    }
    padding-bottom: 30px;
    border-bottom: 1px solid ${Colors.borderGrey};
    :last-of-type {
      border-bottom: none;
    }
  }
`
const TopBorder = styled.div`
  margin-top: -1px;
  width: 100vw;
  z-index: -1;
  border-top: 1px solid ${Colors.borderGrey};
`
const SocialMedia = styled.div`
  display: flex;
  margin-bottom: 5px;
  svg {
    cursor: pointer;
  }
  ${Responsive.tablet} {
    display: block;
  }
`
export default Footer
