import * as React from 'react'

function OpenIcon(props: any) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.375 16.5H2.625M14.25 2.625l-10.5 10.5M14.25 10.328V2.625H6.548"
        stroke="#313131"
        strokeWidth={1.62}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default OpenIcon
