import { useEffect } from 'react'
import styled from 'styled-components'
import { Scrollbar } from '../assets/styles/GlobalStyle'

const NotFound404 = () => {
  //
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  //

  return (
    <>
      <Scrollbar hideScroll={true} />
      <Wrapper>
        <Text>გვერდი ვერ მოიძებნა</Text>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  margin-top: 30vh;
  margin-bottom: 35vh;
  display: flex;
  text-align: center;
  justify-content: center;
`
const Text = styled.div`
  font-size: 40px;
`

export default NotFound404
