import React, { useState } from 'react'
import styled from 'styled-components'
import Responsive from '../config/Responsive'
import Colors from '../styles/Colors'
//

const Accordion: React.FC<{
  children?: React.ReactNode
  title?: string
  content?: string
  openAccordion?: boolean
}> = ({ children, title, content, openAccordion = false }) => {
  //
  const [open, setOpen] = useState<boolean>(openAccordion)
  //
  return (
    <AccordionWrapper>
      <Header $open={open} onClick={() => setOpen(!open)}>
        <span>{title}</span>
        {open ? <Sign>-</Sign> : <Sign>+</Sign>}
      </Header>
      {open === true ? <Content>{children}</Content> : null}
    </AccordionWrapper>
  )
}
//
//
// styles
const Content = styled.div`
  background-color: ${Colors.white};
  padding-inline: 48px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 446px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  white-space: pre-wrap;
  color: rgba(49, 49, 49, 0.8);
  /*  */
  ${Responsive.tablet} {
    padding-inline: 24px;
    padding-bottom: 35px;
  }
  ${Responsive.mobile} {
    padding-inline: 24px;
    padding-bottom: 35px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #313131;
    opacity: 0.8;
    width: 343px;
  }
`
const Header = styled.div<{ $open: boolean }>`
  height: 16px;
  background-color: ${Colors.white};
  padding: ${({ $open }) => ($open === true ? '30px 50px' : '30px 50px')};
  border-radius: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 90%;
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height, or 153% */
  font-feature-settings: 'case' on;
  color: #313131;
  padding-bottom: 10px;
  /* innter titile span */
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    /* identical to box height, or 153% */

    font-feature-settings: 'case' on;

    color: #313131;
    display: flex;
    /* width: 100%; */
  }
  /*  */
  ${Responsive.tablet} {
    height: 34px;
    padding: ${({ $open }) =>
      $open === true ? '18.5px 24px 24px 24px' : '18.5px 24px'};

    /* innter titile span */
    span {
      font-size: 14px;
      color: ${Colors.black};
    }
  }
  ${Responsive.mobile} {
    height: 44px;
    padding: ${({ $open }) =>
      $open === true ? '24px 24px 24px 24px' : '24px 24px'};

    /* innter titile span */
    span {
      font-size: 14px;
      color: ${Colors.black};
    }
  }
`
const AccordionWrapper = styled.div`
  /* -webkit-box-shadow: 0px 0px 15px 3px #88888811;
  box-shadow: 0px 0px 15px 3px #88888811; */
  width: 100%;
  border-radius: 8px;
  /* margin-bottom: 32px; */
  user-select: none;
  border-bottom: 1px solid ${Colors.borderGrey};
  padding-bottom: 15px;

  ${Responsive.tablet} {
    margin-bottom: 20px;
  }
  ${Responsive.mobile} {
    margin-bottom: 0px;
  }
`
const Sign = styled.div`
  font-size: 25px;
  font-weight: 400;
`
export default Accordion
